import React from 'react';
import ClonRVoice from '../../shared/src/models/clonR/clonRVoice';


interface Props {
    voice: ClonRVoice,    
    selectedClonRVoice: ClonRVoice,
    showSelectionBorder: boolean,
    isPlayingVoiceAudio: boolean,
    onSelectionChange: (clonRVoice: ClonRVoice) => void,
    handleStopVoiceClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    handlePlayVoiceClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, voice: ClonRVoice) => void
}


const ClonRVoiceCardCtrl: React.FC<Props> = ({ voice, selectedClonRVoice, showSelectionBorder, isPlayingVoiceAudio, onSelectionChange, handleStopVoiceClick, handlePlayVoiceClick }) => {

    return (
        <div
            key={voice.id}
            onClick={() => onSelectionChange(voice)}
            className={(voice.id === selectedClonRVoice.id && showSelectionBorder) ? "kt-voice-icon kt-selected" : "kt-voice-icon"}
        >
            <i className='fal fa-waveform-path'></i>
            <div className='kt-voice-name'>{voice.name}</div>
            <div className='kt-buttons'>
                {(voice.id === selectedClonRVoice.id && isPlayingVoiceAudio) ?
                    <div onClick={(e) => handleStopVoiceClick(e)}><i className='fas fa-stop'></i></div>
                    :
                    <div onClick={(e) => handlePlayVoiceClick(e, voice)}><i className='fal fa-volume'></i></div>
                }
            </div>
        </div>
    );
}

export default ClonRVoiceCardCtrl;