import React, { useContext, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import LandingPageData, { CalendarVideoGroup } from '../../src/shared/src/models/landingPageData';
import ImageUploadCtrl from './imageUploadCtrl';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RichTextEditDlg from './dialogs/richTextEditDlg';
import CalendarVideosDlg from './dialogs/calendarVideosDlg';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Tooltip from '@mui/material/Tooltip';
import VideoGroupSelectorCtrl from './videoGroupSelectorCtrl';
import CalendarSelectorDlg from './dialogs/calendarSelectorDlg';
import Calendar from '../models/calendar';
import { GlobalContext } from '../GlobalContext';

interface Props {
    id: string,
    landingPage: LandingPageData,
    clientId: string,
    locationId: string,
    previewUrl: string,
    showButtonsCheckbox?: boolean,
    previewButtonDisabled?: boolean,
    onChange: (landingpage: LandingPageData) => void
}


const LandingPageCtrl: React.FC<Props> = ({ id, landingPage, clientId, locationId, previewUrl, showButtonsCheckbox, previewButtonDisabled, onChange }) => {

    const { calendars } = useContext(GlobalContext);

    // const [isBeforeAfterImagesUploadDlgVisible, setIsBeforeAfterImagesUploadDlgVisible] = useState(false);
    const [isDoctorVideosDlgVisible, setIsDoctorVideosDlgVisible] = useState(false);
    const [isDescriptionDlgVisible, setIsDescriptionDlgVisible] = useState(false);
    const [isCalendarSelectorDlgVisible, setIsCalendarSelectorDlgVisible] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);

    useEffect(() => {
        setIsReadOnly(id === "successor" || id === "recall");
    }, [id]);

    function handleOnChange(event) {
        if (landingPage) {
            const { name, value } = event.target;

            const l = landingPage.clone();

            if (event.target.type === "checkbox") {
                l[name] = event.target.checked;
            } else {
                l[name] = value;
            }

            onChange(l);
        }
    }

    function handleImageChange(url) {
        if (url || url === "") {
            const l = landingPage.clone();

            l.image = url;

            onChange(l);
        }
    }


    function handleDoctorVideosDlgSave(_videoGroups: CalendarVideoGroup[]) {
        landingPage.videoGroups = [..._videoGroups];

        setIsDoctorVideosDlgVisible(false);
    }


    function handleDescriptionDlgSave(_description: string) {
        const l = landingPage.clone();

        l.description = _description

        onChange(l);

        setIsDescriptionDlgVisible(false);
    }

    function openNewPreviewTab(selectedCalendar: Calendar) {
        const url = previewUrl + "-" + selectedCalendar.id;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <div>

            <Grid container>

                <Grid item xs={6}>
                    <TextField
                        disabled={isReadOnly}
                        fullWidth
                        name="headline"
                        label="Überschrift"
                        value={landingPage.headline}
                        onChange={handleOnChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    {(showButtonsCheckbox === undefined ? true : showButtonsCheckbox) && <FormControlLabel
                        control={<Checkbox checked={landingPage.showButtons} onChange={handleOnChange} name="showButtons" color="default" />}
                        label="Patienten Aktionen erlauben (Termin absagen, verschieben, Recall/Folgetermin bestätigen)"
                    />}
                    <FormControlLabel
                        control={<Checkbox checked={landingPage.showChatButton} onChange={handleOnChange} name="showChatButton" color="default" />}
                        label="Zeigt einen AI-Chat-Button auf der Landingpage. Wenn der Patient diesen klickt, wird ein AI-Arzt-Patienten Chat eröffnet."
                    />
                </Grid>


                <Grid item xs={6}>
                    <h4>Beschreibung</h4>
                    <div
                        dangerouslySetInnerHTML={{ __html: landingPage.description }}>
                    </div>

                    {!isReadOnly && <>
                        <RichTextEditDlg
                            visible={isDescriptionDlgVisible}
                            richText={landingPage.description}
                            onSave={handleDescriptionDlgSave}
                            onClose={() => setIsDescriptionDlgVisible(false)}
                        />
                        <Button
                            variant={"outlined"}
                            onClick={() => setIsDescriptionDlgVisible(true)}
                        >
                            <i className='fal fa-pencil'></i>&nbsp;Beschreibung bearbeiten
                        </Button>
                    </>
                    }
                </Grid>
                <Grid item xs={6}>
                    {(!isReadOnly || landingPage.image !== "") &&
                        <ImageUploadCtrl
                            disabled={isReadOnly}
                            id={`${id}-visitMotiveImageUploadCtrl`}
                            src={landingPage.image}
                            width={300}
                            clientId={clientId}
                            locationId={locationId}
                            folder="visitMotives"
                            onValueChange={handleImageChange}
                        />
                    }
                </Grid>


                <Grid item xs={6}>
                    <CalendarVideosDlg
                        clientId={clientId}
                        disabled={isReadOnly}
                        visible={isDoctorVideosDlgVisible}
                        videoGroups={landingPage.videoGroups}
                        onSave={handleDoctorVideosDlgSave}
                        onClose={() => setIsDoctorVideosDlgVisible(false)}
                    />
                    <Button
                        variant="outlined"
                        onClick={() => setIsDoctorVideosDlgVisible(true)}
                        startIcon={<i className='fal fa-video'></i>}
                    >
                        ClonR AI Videos
                    </Button>
                </Grid>

                <Grid item xs={6}>
                </Grid>

                <Grid item xs={6}>

                    {(previewButtonDisabled === undefined || previewButtonDisabled === false) ?

                        <>
                            <CalendarSelectorDlg
                                visible={isCalendarSelectorDlgVisible}
                                onCalendarSelect={(selectedCalendar) => openNewPreviewTab(selectedCalendar)}
                                onClose={() => setIsCalendarSelectorDlgVisible(false)}
                            />
                            {calendars.length === 1 ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<VisibilityIcon />}
                                    target='_blank'
                                    href={previewUrl + "-" + calendars[0].id}
                                >
                                    VORSCHAU
                                </Button>
                                :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<VisibilityIcon />}
                                    onClick={() => setIsCalendarSelectorDlgVisible(true)}
                                >
                                    VORSCHAU
                                </Button>
                            }

                        </>
                        :
                        <Tooltip title="Bitte speichern Sie zuerst Ihre Änderungen, um eine Vorschau zu erstellen.">
                            <span>
                                <Button
                                    disabled={true}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<VisibilityIcon />}
                                    target='_blank'
                                    href={previewUrl}
                                >
                                    VORSCHAU
                                </Button>
                            </span>
                        </Tooltip>
                    }

                </Grid>

            </Grid>

        </div>
    );
}

export default LandingPageCtrl;