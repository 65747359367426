import Patient from "./patient"
import Calendar from "./calendar";
import VisitMotive from "./visitMotive";

import VisitMotiveChain from "./visitMotiveChain";
import Gender from "./gender";
import CalendarItem, { CalendarItemType } from "./calendarItem";
import DateUtils from "../../src/shared/src/utils/dateUtils";
import Speciality from "./speciality";

export enum PatientStatus {
    none,
    inTreatment,
    treated,
    inWaitingRoom
}

class Appointment implements CalendarItem {
    id: string = "";
    clientId: string = "";
    locationId: string = "";

    campaignId: string = "";    // the campaign which has created this appointment

    start: Date = new Date();
    end: Date = new Date();
    isMultiDay: boolean = false; // we need this flag to optimise our search query

    calendar: Calendar = new Calendar();
    visitMotive: VisitMotive = new VisitMotive();
    patient: Patient = new Patient();
    patientStatus: PatientStatus = PatientStatus.none;
    resourceId: string = "";
    comments: string = "";

    title: string = ""; // needed for calendar control
    color: string = "";
    borderColor: string = "";

    chainId: string = ""; // if this appointment is part of a chain
    chain: VisitMotiveChain = new VisitMotiveChain(); // only the first appointment stores the whole chain

    createdBy: string = ""; // user id of creator

    roomId: string = "";
    roomName: string = "";

    deviceId: string = "";
    deviceName: string = "";

    isVideoCall: boolean = false;

    calendarItemType: CalendarItemType = "appointment";

    recurrenceCount: number = 0;

    createdAt: Date | null = new Date();

    // "reserved" => user has selected him selfe the appointment, but did not finished the booking process
    // "needsConfirmation" => appointment was created automatically by the system, example as a recall or successor appointment
    status: "reserved" | "confirmed" | "needsConfirmation" | "declined" = "confirmed";

    // stores how often the patient has hit the snooze button for virtual appointments
    remindLaterCount: number = 0;

    // if this is a recall appointment, then the parent is the appointment which triggered the recall
    parentRecallId: string = "";

    // id of recall appointment
    recallId: string = "";

    // if this is a successor appointment, then the predecessor is the appointment which triggered the successor
    predecessorId: string = "";

    // id of successor appointment
    successorId: string = "";

    documentsExpireAt: Date | null = new Date();

    documentsSent: boolean = false;

    clone(): Appointment {
        const a = new Appointment();

        a.id = this.id;
        a.clientId = this.clientId;
        a.locationId = this.locationId;

        a.campaignId = this.campaignId;

        a.start = new Date(this.start);
        a.end = new Date(this.end);
        a.isMultiDay = this.isMultiDay;

        a.calendar = this.calendar.clone();
        a.visitMotive = this.visitMotive.clone();
        a.patient = this.patient.clone();
        a.patientStatus = this.patientStatus;
        a.resourceId = this.resourceId;
        a.comments = this.comments;
        a.title = this.title;

        a.chainId = this.chainId;
        a.chain = this.chain.clone();

        a.createdBy = this.createdBy;

        a.roomId = this.roomId;
        a.roomName = this.roomName;

        a.deviceId = this.deviceId;
        a.deviceName = this.deviceName;

        a.isVideoCall = this.isVideoCall;

        a.calendarItemType = this.calendarItemType;

        a.recurrenceCount = this.recurrenceCount;

        a.createdAt = this.createdAt;

        a.status = this.status;

        a.remindLaterCount = this.remindLaterCount;

        a.parentRecallId = this.parentRecallId;
        a.recallId = this.recallId;

        a.predecessorId = this.predecessorId;
        a.successorId = this.successorId;

        a.documentsExpireAt = this.documentsExpireAt;

        a.documentsSent = this.documentsSent;

        return a;
    }

    toJSON(): object {


        this.isMultiDay = DateUtils.isMultiDay(this.start, this.end);

        let jsonObj = {
            id: this.id,
            clientId: this.clientId,
            locationId: this.locationId,
            campaignId: this.campaignId,
            start: this.start,
            end: this.end,
            isMultiDay: this.isMultiDay,
            calendar: {
                id: this.calendar.id,
                name: this.calendar.name
            },
            resourceId: this.resourceId,
            visitMotive: {
                id: this.visitMotive.id,
                name: this.visitMotive.name,
                color: this.visitMotive.color,
                specialityId: this.visitMotive.specialityId
            },
            patient: {
                id: this.patient.id,
                gender: this.patient.gender,
                firstName: this.patient.firstName,
                lastName: this.patient.lastName,
                newPatient: this.patient.newPatient,
                privateInsurance: this.patient.privateInsurance === true,
                mobilePhoneNumber: this.patient.mobilePhoneNumber,
                phoneNumber: this.patient.phoneNumber,
                city: this.patient.city,
                postalCode: this.patient.postalCode,
                street: this.patient.street
            },
            patientStatus: this.patientStatus,
            createdBy: this.createdBy,
            createdAt: this.createdAt,
            roomId: this.roomId,
            roomName: this.roomName,
            deviceId: this.deviceId,
            deviceName: this.deviceName,

            isVideoCall: this.isVideoCall,

            calendarItemType: this.calendarItemType,

            recurrenceCount: this.recurrenceCount,

            status: this.status,

            remindLaterCount: this.remindLaterCount,

            parentRecallId: this.parentRecallId,
            recallId: this.recallId,

            predecessorId: this.predecessorId,
            successorId: this.successorId,

            // dont save documents states from the client side
            // do this only on server side
            // documentsExpireAt: this.documentsExpireAt,

            // documentsSent: this.documentsSent

        } as any;

        if(this.patient.location) {
            jsonObj.patient.location = this.patient.location;
        }

        jsonObj.comments = this.comments;


        if(this.chainId) {
            jsonObj.chainId = this.chainId;
        }

        if(this.chain && this.chain.columns && this.chain.columns.length > 0){
            jsonObj.chain = JSON.stringify(this.chain);
            //jsonObj.chainId = this.id;
        }

        return jsonObj;

    }

    fromObject(id:string|null, o:any) {

        this.id = id !== null ? id : o.id;

        this.clientId = o.clientId ? o.clientId : "";

        this.locationId = o.locationId ? o.locationId : "";

        this.campaignId = o.campaignId ?? "";

        this.start = DateUtils.getDate(o.start) ?? new Date();
        this.end = DateUtils.getDate(o.end) ?? new Date();
        this.isMultiDay = o.isMultiDay === true;

        this.resourceId = o.resourceId ?? "";

        this.calendar = new Calendar();
        if(o.calendar){
            this.calendar.id = o.calendar.id ? o.calendar.id : "";
            this.calendar.name = o.calendar.name ? o.calendar.name : "";

            if(this.resourceId === "") {
                this.resourceId = this.calendar.id;
            }
        }

        this.visitMotive = new VisitMotive();
        if(o.visitMotive){
            this.visitMotive.id = o.visitMotive.id ? o.visitMotive.id : "";
            this.visitMotive.name = o.visitMotive.name ? o.visitMotive.name : "";
            this.visitMotive.color = o.visitMotive.color ? o.visitMotive.color : "";
            this.visitMotive.specialityId = o.visitMotive.specialityId ? o.visitMotive.specialityId : "";
            this.color = this.visitMotive.color;
        }

        this.patient = new Patient();
        if(o.patient){
            this.patient.id = o.patient.id ? o.patient.id : "";
            this.patient.gender = o.patient.gender ?? Gender.female;
            this.patient.firstName = o.patient.firstName ? o.patient.firstName : "";
            this.patient.lastName = o.patient.lastName ? o.patient.lastName : "";
            this.patient.newPatient = o.patient.newPatient === true;
            this.patient.privateInsurance = o.patient.privateInsurance === true;
            this.patient.mobilePhoneNumber = o.patient.mobilePhoneNumber ? o.patient.mobilePhoneNumber : "";
            this.patient.phoneNumber = o.patient.phoneNumber ? o.patient.phoneNumber : "";

            this.patient.city = o.patient.city ?? "";
            this.patient.street = o.patient.street ?? "";
            this.patient.postalCode = o.patient.postalCode ?? "";
            this.patient.location = o.patient.location ?? "";

            if(!this.title){
                this.title = `${o.patient.lastName.toUpperCase()} ${o.patient.firstName}`;
            }
        }

        this.patientStatus = o.patientStatus ? o.patientStatus : PatientStatus.none;

        this.chainId = o.chainId ? o.chainId : "";

        // try{
        //     this.chain = new VisitMotiveChain();
        //     if(o.chain){
        //         const chain = JSON.parse(o.chain);
        //         this.chain.fromObject(null, chain);
        //     }
        // }catch(e){
        //     console.log(`error in deserialising chain for appointment ${this.id}: ${e}`);
        // }

        this.comments = o.comments ? o.comments : "";

        this.createdBy = o.createdBy ? o.createdBy : "";

        this.roomId = o.roomId ? o.roomId : "";
        this.roomName = o.roomName ? o.roomName : "";

        this.deviceId = o.deviceId ? o.deviceId : "";
        this.deviceName = o.deviceName ? o.deviceName : "";

        this.isVideoCall = o.isVideoCall === true;

        this.calendarItemType = o.calendarItemType ?? "appointment";

        this.recurrenceCount = o.recurrenceCount ? o.recurrenceCount : 0;

        this.createdAt = DateUtils.getDate(o.createdAt);

        this.status = o.status ?? "confirmed";

        this.remindLaterCount = o.remindLaterCount ?? 0;

        this.parentRecallId = o.parentRecallId ?? "";
        this.recallId = o.recallId ?? "";

        this.predecessorId = o.predecessorId ?? "";
        this.successorId = o.successorId ?? "";

        this.documentsExpireAt = o.documentsExpireAt ? DateUtils.getDate(o.documentsExpireAt) : new Date();

        this.documentsSent = o.documentsSent === true;
    }

    isPartOfChain() : boolean {

        if(this.chainId) {
            return true;
        }

        if(this.chain && this.chain.columns.length > 1){
            return true;
        }

        return false;
    }

    getTitle(): string {

        if(this.calendarItemType === "reminder"){
            return this.comments;
        }

        if(this.calendarItemType === "absence"){
            return "Abwesenheit";
        }

        let result = "";

        if(this.visitMotive){
            result = this.visitMotive.name;
        }

        if(this.createdBy === "recaller"){
            result += ` - Recall`
        } else if(this.createdBy === "predecessor"){
            result += ` - Folgetermin`;
        }

        return result;
    }

    allDocumentsComplete(): boolean {
        const today = new Date();
        return this.documentsExpireAt === null || this.documentsExpireAt.getTime() > today.getTime();
    }

    isRecall(): boolean {
        return this.parentRecallId !== "" && this.createdBy === "recaller";
    }

    isSuccessor(): boolean {
        return this.predecessorId !== "" && this.createdBy === "predecessor";
    }

    isVirtual(): boolean {
        return (this.isRecall() || this.isSuccessor()) && this.status === "needsConfirmation";
    }

    updateColorBySpeciality(specialities: Speciality[]){
        const speciality = specialities.find(s => s.id === this.visitMotive.specialityId);
        if(speciality){
            this.color = speciality.color;
        }
    }
};



export default Appointment;