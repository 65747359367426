import ClonRScript from "../shared/src/models/clonR/clonRScript";
import ClonRVoice from "../shared/src/models/clonR/clonRVoice";
import GPTChatMessage from "../shared/src/models/clonR/gptChatMessage";
import Utils from "../shared/src/utils/utils";
import database from "./../components/database";
import firebase from 'firebase/compat/app';
import ClonRVoicesService from "./clonRVoicesService";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import TextToSpeech from "../shared/src/models/clonR/textToSpeech";

const functions = firebase.app().functions('europe-west3');

const db = database.firestore();

const ClonRScriptsService = {


    async getScripts(clientId: string): Promise<ClonRScript[] | null> {

        const scripts: ClonRScript[] = [];

        try {

            if (clientId) {
                const querySnapshot = await db.collection("clients").doc(clientId)
                    .collection("clonRScripts")
                    .orderBy("createdAt", "desc")
                    .get();


                querySnapshot.forEach((doc) => {

                    const defaultLanguage = doc.data()?.defaultLanguage ?? "de";

                    const script = new ClonRScript(defaultLanguage);
                    script.fromObject(doc.id, doc.data());

                    scripts.push(script);

                });

            }

            return scripts;

        } catch (error) {
            console.log("Error getting scripts: ", error);
            return null;
        }

    },

    async getScript(clientId: string, scriptId: string): Promise<ClonRScript | null> {

        if (!scriptId) return null;


        const doc = await db.collection("clients").doc(clientId)
            .collection("clonRScripts").doc(scriptId)
            .get();

        try {

            if (doc.exists) {

                const defaultLanguage = doc.data()?.defaultLanguage ?? "de";

                const script = new ClonRScript(defaultLanguage);
                script.fromObject(scriptId, doc.data());

                return script;

            } else {
                console.log("getScript: No such document: " + scriptId);
                return null;
            }

        } catch (error) {
            console.log("Error getting script: ", error);
            return null;
        };

    },



    async updateScript(script: ClonRScript, clientId: string): Promise<string | null> {

        try {

            if (script.id) {
                await db.collection("clients").doc(clientId)
                    .collection("clonRScripts").doc(script.id)
                    .set(script.toJSON(), { merge: true });

                return script.id;

            } else {
                // create a new ClonRScript
                const docRef = await db.collection("clients").doc(clientId)
                    .collection("clonRScripts")
                    .add(script.toJSON());

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating script: ", error);
        };

        return null;
    },

    async deleteScript(scriptId: string, clientId: string): Promise<void> {

        try {
            const documentPath = `clients/${clientId}/clonRScripts/${scriptId}`;
            await db.doc(documentPath).delete();

        } catch (error) {
            console.log("Error deleting ClonRScript: ", error);
        };
    },

    startGPTChat(requestId: string, messages: GPTChatMessage[]): void {

        console.log(requestId);

        const getGPTChatResponse = functions.httpsCallable('getGPTChatResponse');
        getGPTChatResponse(
            {
                requestId: requestId,
                messages: messages
            }
        );
    },

    listenForGPTChatResponse(requestId: string, changeCallback: (message: GPTChatMessage | null, finishReason: string) => void): () => void {

        return db.collection("gptStreams")
            .doc(requestId)
            .onSnapshot(doc => {

                try {

                    if (doc.exists) {
                        const _doc = doc.data();
                        if (_doc) {
                            const _message: GPTChatMessage = {
                                content: _doc.content,
                                role: "assistant"
                            }
                            changeCallback(_message, _doc.finishReason);
                            return;
                        }
                    }

                } catch (err) {
                    console.error("error in listenForGPTChatResponse:" + err);
                }

                changeCallback(null, "");

            });
    },

    async getOrGenerateScriptAudio(
        selectedClonRScript: ClonRScript, 
        selectedLanguage: string, 
        selectedClonRVoice: ClonRVoice, 
        clientId: string, 
        finishedCallback: (tts: TextToSpeech)=>void) {

            //console.log(`getOrGenerateScriptAudio script id: ${selectedClonRScript.id} lang: ${selectedLanguage} voice: ${selectedClonRVoice.id} client id: ${clientId}`);

        const _script = selectedClonRScript.clone();

        const translation = _script.translations.find(t => t.language === selectedLanguage);
        if (!translation) return;

        // make sure the script is saved before we generate the audio
        if (!_script.id) {
            const newId = await ClonRScriptsService.updateScript(_script, clientId);
            if (!newId) return;

            _script.id = newId;
        }

        // first check if we need to generate a new audio
        // if the text hash has not been changed then we do not generate a new audio
        const newHash = Utils.getHash(translation.text + selectedClonRVoice.id);

        // console.log(`getOrGenerateScriptAudio newHash: ${newHash} translation old hash: ${translation.audioTextHash}`);
        // console.log(translation);

        if ((translation.audioUrl === "" || newHash !== translation.audioTextHash) && translation.text && selectedClonRVoice.customVoiceId && clientId) {

            const audioId = Utils.getUUID();

            translation.audioId = audioId;
            translation.audioUrl = "";
            translation.audioTextHash = newHash;

            ClonRScriptsService.updateScript(_script, clientId);

            ClonRVoicesService.startListenForTTS(audioId, finishedCallback)

            // generate the audio
            ClonRVoicesService.generateTTS(audioId, translation.text, "", selectedClonRVoice.customVoiceId, clientId);

            return audioId;
        }

        const tts = new TextToSpeech();
        tts.status = "fromcache"
        finishedCallback(tts);

        return "";

    }


}

export default ClonRScriptsService;