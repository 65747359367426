import Paper from '@mui/material/Paper';
import React, { useContext, useEffect, useState } from 'react';
import ClonRVoice from '../../shared/src/models/clonR/clonRVoice';
import ConfirmDialog from '../dialogs/confirmDialog';

import { GlobalContext } from '../../GlobalContext';
import ClonRVoiceDialog from '../dialogs/clonRDialog/clonRVoicesDialog';
import ClonRVoicesService from '../../services/clonRVoicesService';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const ClonRVoicesCtrl: React.FC = () => {

    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const [isClonRVoiceDialogOpen, setIsClonRVoiceDialogOpen] = useState(false);

    const [ownVoices, setOwnVoices] = useState<ClonRVoice[]>([]);
    const [publicVoices, setPublicVoices] = useState<ClonRVoice[]>([]);
    const [selectedClonRVoice, setSelectedClonRVoice] = useState(new ClonRVoice());

    const [isPlaying, setIsPlaying] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const { currentUser } = useContext(GlobalContext);

    useEffect(() => {
        loadVoices();

        function setNoPlayingState(){
            setIsPlaying(false);
        }
        const audioPlayer = document.getElementById("voice-preview") as HTMLAudioElement;
        audioPlayer.addEventListener("ended", setNoPlayingState);
        return () => audioPlayer.removeEventListener('click', setNoPlayingState);

    }, []);

    const loadVoices = async () => {
        const _voices = await ClonRVoicesService.getVoices(currentUser.clientId);
        if (_voices) {
            setOwnVoices(_voices.filter(a => a.isSystem === false));
            setPublicVoices(_voices.filter(a => a.isSystem === true));
        }
        setIsLoading(false);
    }


    const addNewVoice = () => {
        setSelectedClonRVoice(new ClonRVoice());
        setIsClonRVoiceDialogOpen(true);
    }

    const handleSaveClonRVoice = async (newClonRVoice: ClonRVoice) => {

        if (newClonRVoice.createdBy === "") { newClonRVoice.createdBy = currentUser.id; }

        await ClonRVoicesService.updateVoice(newClonRVoice, currentUser.clientId);

        setIsClonRVoiceDialogOpen(false);

        loadVoices();
    }

    const handleEditVoiceClick = async (voice: ClonRVoice) => {
        setSelectedClonRVoice(voice);
        setIsClonRVoiceDialogOpen(true);
    }

    const handleDeleteVoiceClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, voice: ClonRVoice) => {
        e.stopPropagation();

        setSelectedClonRVoice(voice);
        setIsConfirmDeleteDialogOpen(true);
    }

    const deleteVoice = async () => {
        await ClonRVoicesService.deleteVoice(selectedClonRVoice, currentUser.clientId);
        setIsConfirmDeleteDialogOpen(false);
        setSelectedClonRVoice(new ClonRVoice());

        loadVoices();
    }

    const handlePlayVoiceClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, voice: ClonRVoice) => {
        e.stopPropagation();

        setSelectedClonRVoice(voice);

        const audioPlayer = document.getElementById("voice-preview") as HTMLAudioElement;

        audioPlayer.src = voice.audioUrl;
        audioPlayer.play();

        setIsPlaying(true);
    }

    const handleStopVoiceClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        const audioPlayer = document.getElementById("voice-preview") as HTMLAudioElement;

        audioPlayer.pause();
        audioPlayer.currentTime = 0;

        setIsPlaying(false);
    }


    function VoiceCard(voice: ClonRVoice) {
        return (
            <div onClick={() => handleEditVoiceClick(voice)} className={voice.id === selectedClonRVoice.id ? "kt-voice-icon kt-selected" : "kt-voice-icon"}>
                <i className='fal fa-waveform-path'></i>
                <div className='kt-voice-name'>{voice.name}</div>
                <div className='kt-buttons'>
                    {(voice.id === selectedClonRVoice.id && isPlaying) ?
                        <div onClick={(e) => handleStopVoiceClick(e)}><i className='fas fa-stop'></i></div>
                        :
                        <div onClick={(e) => handlePlayVoiceClick(e, voice)}><i className='fal fa-volume'></i></div>
                    }
                    {!voice.isSystem && <div onClick={(e) => handleDeleteVoiceClick(e, voice)}><i className='fal fa-trash'></i></div>}
                </div>
            </div>
        );
    }


    return (
        <>
            <ConfirmDialog
                visible={isConfirmDeleteDialogOpen}
                title='Stimme löschen?'
                label={`Wollen Sie die Stimme ${selectedClonRVoice.name} wirklich löschen?`}
                onReject={() => setIsConfirmDeleteDialogOpen(false)}
                onConfirm={deleteVoice}
            />

            <ClonRVoiceDialog
                clonRVoice={selectedClonRVoice}
                visible={isClonRVoiceDialogOpen}
                onSave={handleSaveClonRVoice}
                onCancel={() => setIsClonRVoiceDialogOpen(false)}
            />

            <audio id="voice-preview" />

            <Paper className="kt-paper">
                <div className='kt-paper-title'>Stimmen</div>

                <div className='kt-big-add-button' onClick={addNewVoice}>
                    <img src="/images/plus-icon.png"></img>
                    <Typography variant="body2">
                        <strong>Neue Stimme erstellen</strong>
                    </Typography>
                </div>

                {(!isLoading && ownVoices.length === 0) && <div>Es sind noch keine Stimmen vorhanden.</div>}
                {(isLoading) && <div className='kt-loading'>Stimmen werden geladen... <i className='fas fa-spinner-third fa-spin'></i></div>}

                <h3>Meine Stimmen ({ownVoices.length})</h3>
                <Box display={"flex"} flexWrap={"wrap"} gap={1} className="kt-justify-content-center-mobile">
                    {ownVoices.map(v => <Box key={v.id}>{VoiceCard(v)}</Box>)}
                </Box>

                <h3>Bibliothek ({publicVoices.length})</h3>
                <Box display={"flex"} flexWrap={"wrap"} gap={1} className="kt-justify-content-center-mobile">
                    {publicVoices.map(v => <Box key={v.id}>{VoiceCard(v)}</Box>)}
                </Box>

            </Paper>
        </>
    );
}

export default ClonRVoicesCtrl;