import Paper from '@mui/material/Paper';
import React, { useContext, useState } from 'react';
import ClonRScript, { ScriptMode, Translation } from '../../shared/src/models/clonR/clonRScript';
import ClonRScriptsService from '../../services/clonRScriptsService';

import { GlobalContext } from '../../GlobalContext';
import MessageDialog from '../dialogs/messageDialog';
import Dialog from '@mui/material/Dialog';
import ClonRGPTCtrl from './clonRGPTCtrl';
import TextField from '@mui/material/TextField';
import CountryFlag from '../countryFlag';
import QRCode from 'react-qr-code';
import TranslationsService from '../../services/translationsService';
import { Button, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmDialog from '../dialogs/confirmDialog';
import ClonRSettings from '../../shared/src/models/clonR/clonRSettings';

interface Props {
    clonRSettings: ClonRSettings;
    selectedLanguage: string;
    selectedScript: ClonRScript;
    onSelectedScriptChange: (newSelectedScript: ClonRScript) => void;
    onSelectedLanguageChange: (newSelectedLanguage: string) => void;
}

const ClonRScriptCtrl: React.FC<Props> = ({ clonRSettings, selectedScript, selectedLanguage, onSelectedScriptChange, onSelectedLanguageChange }) => {

    const [scripts, setScripts] = useState<ClonRScript[]>([]);
    const [isLoadingScripts, setIsLoadingScripts] = useState(false);
    const [isTranslating, setIsTranslating] = useState(false);

    const [isGTPDialogVisible, setIsGTPDialogVisible] = useState(false);
    const [isScriptsDialogVisible, setIsScriptsDialogVisible] = useState(false);
    const [isNewLanguageDialogVisible, setIsNewLanguageDialogVisible] = useState(false);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);

    const [selectedLanguageText, setSelectedLanguageText] = useState("");
    const [scriptToDelete, setScriptToDelete] = useState<null | ClonRScript>(null);

    const [errorMessage, setErrorMessage] = useState("");

    const { currentUser } = useContext(GlobalContext);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const openScriptsDialog = async () => {
        setIsLoadingScripts(true);
        setIsScriptsDialogVisible(true);
        await loadScripts();
        setIsLoadingScripts(false);
    }

    const loadScripts = async () => {
        const _scripts = await ClonRScriptsService.getScripts(currentUser.clientId);
        if (_scripts) setScripts(_scripts);
    }

    const setScriptMode = (newMode: ScriptMode) => {
        const s = selectedScript.clone();
        s.scriptMode = newMode;
        onSelectedScriptChange(s);
    }

    const setScriptName = (newName: string) => {
        const s = selectedScript.clone();
        s.name = newName;
        onSelectedScriptChange(s);
    }

    const setScriptText = (newText: string) => {

        const s = selectedScript.clone();

        let translIndex = s.translations.findIndex(t => t.language === selectedLanguage);

        if (translIndex !== -1) {
            s.translations[translIndex].text = newText;
        } else {
            const transl: Translation = {
                language: selectedLanguage,
                text: newText,
                audioId: "",
                audioUrl: "",
                audioLength: 0,
                audioTextHash: 0,
                status: ""
            }
            s.translations.push(transl);
        }

        onSelectedScriptChange(s);

        setSelectedLanguageText(newText);
    }

    const handleOnScriptClick = (s: ClonRScript) => {
        onSelectedScriptChange(s);
        onSelectedLanguageChange(s.defaultLanguage);
        setSelectedLanguageText(s.getDefaultLanguageTranslation().text);
        setIsScriptsDialogVisible(false);

        console.log(`script ID: ${s.id}`);
    }

    const openLanguageSelector = () => {
        const defaultLanguageTranslation = selectedScript.getDefaultLanguageTranslation();

        if (defaultLanguageTranslation.text.trim().length < 5) {
            setErrorMessage("Bitte geben Sie einen Skripttext ein, mit mindestens 5 Zeichen.");
            return;
        }

        setIsNewLanguageDialogVisible(true);
    }

    const handleOnLanguageClick = async (lang: string) => {

        setIsNewLanguageDialogVisible(false);

        let translation = selectedScript.translations.find(t => t.language === lang);
        if (translation && translation.text !== "") {
            setSelectedLanguageText(translation.text);

        } else {

            const defaultLanguageTranslation = selectedScript.getDefaultLanguageTranslation();

            setIsTranslating(true);
            const translatedText = await TranslationsService.translate(defaultLanguageTranslation.text, lang, "deepl");

            translation = {
                language: lang,
                text: translatedText,
                audioId: "",
                audioUrl: "",
                audioLength: 0,
                audioTextHash: 0,
                status: ""
            }

            const s = selectedScript.clone();
            s.translations.push(translation);
            onSelectedScriptChange(s);

            setSelectedLanguageText(translatedText);

            setIsTranslating(false);
        }

        onSelectedLanguageChange(lang);

    }

    const handleOnNewScriptClick = () => {
        const newScript = new ClonRScript(clonRSettings.defaultLanguage);
        onSelectedScriptChange(newScript);
        setSelectedLanguageText("");
        onSelectedLanguageChange(newScript.defaultLanguage);
    }

    const handleOnSaveScriptClick = async () => {
        if (selectedScript.name.trim() === "") {
            setErrorMessage("Bitte geben Sie einen Skriptnamen ein.")

        } else {
            setErrorMessage("");

            const tempScript = selectedScript.clone();

            // generate all other translations before we save
            if (clonRSettings.isAutoTranslateEnabled && clonRSettings.autoLanguageKeys.length > 0) {
                const defaultLanguageTranslation = selectedScript.getDefaultLanguageTranslation();

                setIsTranslating(true);

                for (let i = 0; i < clonRSettings.autoLanguageKeys.length; i++) {
                    const lang = clonRSettings.autoLanguageKeys[i];

                    if (lang === clonRSettings.defaultLanguage) continue;

                    const translatedText = await TranslationsService.translate(defaultLanguageTranslation.text, lang, "deepl");

                    const translation: Translation = {
                        language: lang,
                        text: translatedText,
                        audioId: "",
                        audioUrl: "",
                        audioLength: 0,
                        audioTextHash: 0,
                        status: ""
                    }

                    let index = selectedScript.translations.findIndex(t => t.language === lang);
                    if (index !== -1) {
                        tempScript.translations[index] = translation;
                    } else {
                        tempScript.translations.push(translation);
                    }
                }

                setIsTranslating(false);

            }

            const newId = await ClonRScriptsService.updateScript(tempScript, currentUser.clientId);
            if (newId) {
                tempScript.id = newId;
                onSelectedScriptChange(tempScript);
            }
        }
    }

    const handleOnDeleteScriptClick = (s: ClonRScript) => {
        setScriptToDelete(s);
        setIsConfirmDeleteDialogOpen(true);
    }

    const deleteScript = async () => {
        if (scriptToDelete) {
            await ClonRScriptsService.deleteScript(scriptToDelete.id, currentUser.clientId);

            setIsConfirmDeleteDialogOpen(false);
            setScriptToDelete(null);

            loadScripts();
        }
    }

    const deleteTranslation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, language: string) => {

        e.stopPropagation();

        onSelectedLanguageChange(selectedScript.defaultLanguage);

        const script = selectedScript.clone();

        let index = 0;

        // in bad situations we can have the same language more than one time in the list
        while (index > -1){
            index = script.translations.findIndex((t) => { return t.language === language; });
            if (index !== -1) script.translations.splice(index, 1);
        }

        onSelectedScriptChange(script);

        console.log(script.defaultLanguage);
    }

    // Need to wrap it, otherwise we get warnings
    // see: https://stackoverflow.com/questions/58103542/material-ui-button-in-a-tab-list
    const AddNewLanguageButton = () => {
        return <Button style={{ borderLeft: "1px solid lightgrey" }} onClick={openLanguageSelector}><i className='fa fa-plus'></i></Button>
    };

    return (
        <>
            <ConfirmDialog
                visible={isConfirmDeleteDialogOpen}
                title='Skript löschen?'
                label={`Wollen Sie das Skript ${scriptToDelete?.name} wirklich löschen?`}
                onReject={() => setIsConfirmDeleteDialogOpen(false)}
                onConfirm={deleteScript}
            />

            <MessageDialog
                visible={errorMessage.length > 0}
                title="Fehler"
                titleIconFaClassName='fa fa-exclamation'
                message={errorMessage}
                onClose={() => setErrorMessage("")}
            />

            <Dialog
                onClose={() => setIsScriptsDialogVisible(false)}
                aria-labelledby="simple-dialog-title"
                open={isScriptsDialogVisible}
                maxWidth="md"
                disableEnforceFocus={true}
                fullWidth={true}
                fullScreen={fullScreen}
            >
                <DialogTitle id="simple-dialog-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    Wählen Sie ein Skript aus
                    <IconButton onClick={() => setIsScriptsDialogVisible(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {isLoadingScripts ?
                    <h2 className='kt-text-center'>Skripte werden geladen... <i className='fas fa-spinner-third fa-spin'></i></h2>
                    :
                    <List>
                        {scripts.map((s) => (
                            <ListItem key={s.id}>
                                <ListItemButton onClick={() => handleOnScriptClick(s)}>
                                    <ListItemIcon>
                                        <i className='fal fa-file-alt'></i>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<>
                                            <span>{s.name}</span>
                                        </>}
                                        secondary={<>
                                            <span style={{ maxWidth: "70%", display: "block" }} className='kt-text-overflow-ellipsis'>{s.getDefaultLanguageTranslation().text}</span>
                                            {s.translations.map(t =>
                                                <span key={t.language} style={{ marginRight: "2px" }}><CountryFlag width={15} countryCode={t.language} /></span>
                                            )}
                                        </>}
                                    />
                                </ListItemButton>
                                <ListItemSecondaryAction>
                                    <ListItemButton aria-label="delete" onClick={() => handleOnDeleteScriptClick(s)} key={s.id}>
                                        <ListItemIcon>
                                            <i className='fal fa-trash'></i>
                                        </ListItemIcon>
                                    </ListItemButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}

                    </List>
                }

            </Dialog>

            <Dialog
                maxWidth="md"
                disableEnforceFocus={true}
                fullWidth={true}
                fullScreen={fullScreen}
                onClose={() => setIsGTPDialogVisible(false)}
                aria-labelledby="simple-dialog-title"
                open={isGTPDialogVisible}>
                <DialogTitle id="simple-dialog-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    GTP Script Assistent
                    <IconButton onClick={() => setIsGTPDialogVisible(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <ClonRGPTCtrl />
            </Dialog>

            <Dialog
                onClose={() => setIsNewLanguageDialogVisible(false)}
                aria-labelledby="simple-dialog-title"
                open={isNewLanguageDialogVisible}
                fullScreen={fullScreen}
            >
                <DialogTitle id="simple-dialog-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    Wählen Sie eine neue Sprache aus
                    <IconButton onClick={() => setIsNewLanguageDialogVisible(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <List>
                    {TranslationsService.languages.map((lang) => (
                        (selectedScript.translations.findIndex(t => t.language === lang.key) === -1) &&
                        <ListItemButton onClick={() => handleOnLanguageClick(lang.key)} key={lang.key}>
                            <ListItemIcon>
                                <CountryFlag countryCode={lang.key} width={30} />
                            </ListItemIcon>
                            <ListItemText primary={lang.name} />
                        </ListItemButton>
                    ))}

                </List>
            </Dialog>



            <div>
                {/* <Tabs value={selectedScript.scriptMode} onChange={(e, i) => setScriptMode(i)} disabled={isTranslating}>
                    <Tab label="Text Script" />
                    <Tab label="Audio Script" />
                </Tabs> */}
                <Tabs
                    value={selectedLanguage}
                    onChange={(e, i) => handleOnLanguageClick(i)}
                    disabled={isTranslating}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    {selectedScript.translations.map(t =>
                        <Tab
                            key={t.language}
                            value={t.language}
                            label={<>
                                <CountryFlag width={15} countryCode={t.language} />
                                <span style={{ marginLeft: "3px" }}>{t.language}</span>
                                {(t.language !== selectedScript.defaultLanguage) &&
                                    <div style={{ marginLeft: "15px", color: "grey" }} onClick={(e) => deleteTranslation(e, t.language)}><i className='fal fa-times'></i></div>
                                }
                            </>}
                            style={{ display: "flex", flexDirection: "row" }}
                        />
                    )}

                    {(selectedScript.id !== "") &&
                        <AddNewLanguageButton />
                    }
                </Tabs>

                {/* {selectedScript.scriptMode === ScriptMode.text && */}
                <Paper className='kt-paper kt-video-script'>
                    <div>
                        <TextField
                            value={selectedScript.name}
                            onChange={(e) => setScriptName(e.target.value)}
                            label="Skriptname"
                            required
                        />
                    </div>
                    <div>
                        {isTranslating ?
                            <TextField
                                rows={6}
                                multiline
                                fullWidth
                                value="Text wird übersetzt..."
                                disabled
                            />
                            :
                            <TextField
                                rows={6}
                                multiline
                                fullWidth
                                value={selectedLanguageText}
                                onChange={e => setScriptText(e.currentTarget.value)}
                            />
                        }

                    </div>
                    <div className="kt-script-buttons">
                        <Button variant="outlined" color='secondary' onClick={() => setIsGTPDialogVisible(true)} disabled={isTranslating}>GTP Assist</Button>
                        <div></div>
                        <div></div>
                        <Button onClick={handleOnNewScriptClick} variant="outlined" color='secondary' disabled={isTranslating}>Neu</Button>
                        <Button onClick={openScriptsDialog} variant="outlined" color='secondary' disabled={isTranslating}>Laden</Button>
                        <Button onClick={handleOnSaveScriptClick} variant="outlined" color='secondary' disabled={isTranslating}>Speichern</Button>
                    </div>
                </Paper>
                {/* } */}

                {/* {selectedScript.scriptMode === ScriptMode.audio &&
                    <Paper className='kt-paper kt-audio-script'>
                        <Button variant="outlined" color='secondary'>Audioscript hochladen</Button>
                        <br />
                        <div className='kt-hide-on-mobile'>
                            <p>ODER</p>
                            <QRCode
                                value="https://cal.pickadoc.de/clonr/newvideo"
                                size={120}
                            />
                            <div>Audioscript mit Smartphone erstellen</div>
                        </div>
                    </Paper>
                } */}
            </div>
        </>
    );
}

export default ClonRScriptCtrl;