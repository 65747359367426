import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";

import DataTable from "../../dataTable";

import ClientsService from "../../../services/clientsService";
import LoadingPage from './loadingPage';
import Client from '../../../models/client';
import { GlobalContext } from '../../../GlobalContext';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';
import { deDE } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function ClientsPage() {

    const navigate = useNavigate();

    const [clients, setClients] = useState<Client[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        loadData();

        // eslint-disable-next-line
    }, []);

    const loadData = async () => {
        setIsLoading(true);

        const result = await ClientsService.getAllClients();

        setIsLoading(false);
        if (result) setClients(result);
    }
    
    const columns: any[] = [

        {
            field: 'name', headerName: 'Name', width: 300, renderCell: (params) => (
                <Link to={`/administration/client/${params.row.id}`}>{params.row.name}</Link>
            )
        },
        {
            field: 'city', headerName: 'Stadt', width: 300, renderCell: (params) => (
                <Link to={`/administration/client/${params.row.id}`}>{params.row.city}</Link>
            )
        },
        {
            field: 'locations', headerName: 'Standorte', width: 300, renderCell: (params) => (
                <Link to={`/administration/client/${params.row.id}`}>{params.row.locations?.length}</Link>
            )
        },
        // { field: 'landingpage', headerName: 'Landingpage', width: 300, renderCell: (params) => (
        //     <Link to={`https://pickadoc.de/profile/${params.row.id}`}>LP</Link>
        // )},
        {
            field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
                <IconButton onClick={(e) => onDeleteRowClick(params.row)}>
                    <DeleteIcon />
                </IconButton>)
        }
    ];


    function onDeleteRowClick(row) {
        const reallyDelete = window.confirm(`Wollen Sie den Kunden ${row.name} wirklich löschen?`);
        if (reallyDelete) ClientsService.deleteClient(row.id);

    }

    if (isLoading) return <LoadingPage />;

    return (        
        <Box className="kt-page">
            <h2>Kunden</h2>

            <Grid container>
                <Grid item>
                    <ButtonGroup>
                        <Button component={Link} to="https://pickadoc.de/installation" startIcon={<AddCircleOutlineIcon />}>
                            Kunden anlegen
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Box className="kt-data-grid-container">
                <DataGridPro
                    loading={isLoading}
                    rows={clients}
                    columns={columns}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

        </Box>
    );
}

export default ClientsPage;