// interface for an item which must have an id
interface IIdItem {
    id: string;
}

const Utils = {


    getUUID(): string {
        let dt = new Date().getTime();
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            /* tslint:disable:no-bitwise */
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
            /* tslint:enable:no-bitwise */
        });
        return uuid;
    },


    doRequest(url: string) {
        return new Promise(function (resolve, reject) {
            const request = require("request");
            request(url, function (error: any, res: any, body: any) {
                if (!error && res.statusCode === 200) {
                    resolve(body);
                } else {
                    reject(error);
                }
            });
        });
    },


    // creates truncted strings out of an input string,
    // where each string length is truncated by 1 more than the last created string
    // strings are truncated from the end of the string
    // example:
    // input: "Mustermann", length: 2
    // output: ["mustermann", "musterman", "musterma", "musterm", "muster", "muste", "must", "mus", "mu", ]
    getTruncatedStartStrings(input: string, length: number): string[] {

        const result: string[] = [];

        if (!input || length < 0) {
            return result;
        }

        // make lower case and remova ALL whitespaces
        const _input = input.toLowerCase().replace(/\s+/g, "");

        if (_input.length > length) {
            const iterations = (_input.length + 1) - length;
            for (let i = 0; i < iterations; i++) {
                const truncatedString = _input.slice(0, _input.length - i);

                result.push(truncatedString);
            }

        } else {
            result.push(_input);
        }

        return result;
    },



    getWithLeadingZero(num: number): string {
        if (num < 10 && num >= 0) {
            return "0" + num;
        } else if (num > -10 && num < 0) {
            return "-0" + Math.abs(num);
        } else {
            return "" + num;
        }
    },

    trimPhoneNumberWithoutCountryCode(_phoneNumber: string): string {

        let phoneNumber = _phoneNumber;

        if (phoneNumber && typeof phoneNumber === "string") {

            // remove all white space
            phoneNumber = phoneNumber.replace(/\s+/g, "");

            // remove all - / . , # characters
            phoneNumber = phoneNumber.replace(/-+/g, "");
            phoneNumber = phoneNumber.replace(/\/+/g, "");
            phoneNumber = phoneNumber.replace(/\.+/g, "");
            phoneNumber = phoneNumber.replace(/,+/g, "");
            phoneNumber = phoneNumber.replace(/#+/g, "");

        }

        return phoneNumber;
    },

    // returns allways the phone number with german country code +49
    trimPhoneNumber(_phoneNumber: string) {

        let phoneNumber = _phoneNumber;

        if (phoneNumber && typeof phoneNumber === "string") {

            phoneNumber = Utils.trimPhoneNumberWithoutCountryCode(phoneNumber);

            if (phoneNumber.indexOf("0049") === 0) {
                phoneNumber = "+49" + phoneNumber.slice(4, phoneNumber.length);
            } else if (phoneNumber.slice(0, 1) === "0") {
                phoneNumber = "+49" + phoneNumber.slice(1, phoneNumber.length);
            }
        }

        return phoneNumber;
    },


    isGermanMobileNumber(_phoneNumber: string): boolean {
        const mobileCodes = ["0151", "0160", "0170", "0171", "0175", "0152", "0162", "0172", "0173", "0174", "0157", "0163", "0177", "0178", "0159", "0176", "0179"];

        let phoneNumber = _phoneNumber;

        if (phoneNumber && phoneNumber.length > 8) {
            phoneNumber = Utils.trimPhoneNumber(phoneNumber);

            phoneNumber = phoneNumber.replace("0049", "0");
            phoneNumber = phoneNumber.replace("+49", "0");

            for (let i = 0; i < mobileCodes.length; i++) {
                const code = mobileCodes[i];

                if (phoneNumber.indexOf(code) === 0) {
                    return true;
                }
            }
        }

        return false;
    },



    // a little function to help us with reordering the result
    reorder<Type>(list: Type[], startIndex: number, endIndex: number): Type[] {
        const result = Array.from<Type>(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    },

    /**
     * Moves an item from one list to another list.
     */
    move<Type>(sourceList: Type[], destinationList: Type[], sourceIndex: number, destinationIndex: number): { sourceList: Type[], destinationList: Type[] } {
        const sourceClone = Array.from<Type>(sourceList);
        const destClone = Array.from<Type>(destinationList);
        const [removed] = sourceClone.splice(sourceIndex, 1);

        destClone.splice(destinationIndex, 0, removed);

        const result = {
            sourceList: sourceClone,
            destinationList: destClone
        };

        return result;
    },


    removeItemFromArray<Type extends IIdItem>(item: Type, array: Type[]): Type[] {
        return Utils.removeItemByIdFromArray(item.id, array);
    },

    removeItemByIdFromArray<Type extends IIdItem>(itemId: string, array: Type[]): Type[] {
        const _array = [...array];

        const index = _array.findIndex((o) => {
            return o.id === itemId;
        });
        if (index !== -1) _array.splice(index, 1);

        return _array;
    },



    getGenderString(gender: any): string {
        if (gender === "m" || gender === "f") return gender === "m" ? "Herr" : "Frau";

        if (gender !== undefined && gender !== null) return gender;

        return "";
    },

    addUniqueToArray(arr: any[], item: any): any[] {
        if (arr) {
            if (arr.indexOf(item) === -1) {
                arr.push(item);
            }
        }

        return arr;
    },


    getYouTubeId(url: string): string {

        const trimmedUrl = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (trimmedUrl[2] !== undefined) {
            return trimmedUrl[2].split(/[^0-9a-z_\-]/i)[0];
        }
        else {
            return trimmedUrl.toString();
        }
    },

    getHash(str: string): number {
        let hash = 0;
        let i, chr;

        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }

        return hash;
    },

    getRandomIntInclusive(min: number, max: number): number {
        const minCeiled = Math.ceil(min);
        const maxFloored = Math.floor(max);
        return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
    }


};

export default Utils;