import database from "../components/database";

import GlobalSettings from "../../shared/src/models/globalSettings";

const db = database.firestore();

const SettingsService = {
        
    async getGlobalSettings():Promise<GlobalSettings | null> {

        try {

            const doc = await db.collection("settings").doc("global").get();

            if (doc.exists) {
                return doc.data()! as GlobalSettings;
            } else {
                console.log("getGlobalSettings: No such document: 'global'");
                return null;
            }

        } catch(error){
            console.log("Error getting global settings: ", error);
            return null;
        }

    }

   
}

export default SettingsService;