// import { useState, useEffect, useContext } from 'react';
// import { Link, useNavigate, useParams } from "react-router-dom";

// import DataTable from "../../dataTable";

// import ClientsService from "../../../services/clientsService";
// import UsersService from '../../../services/usersService';
// import LoadingPage from './loadingPage';
// import Client from '../../../models/client';
// import ClientSettings from '../../../models/clientSettings';
// import User from '../../../models/user';
// import { GlobalContext } from '../../../GlobalContext';

function ClientPage() {

    //     const navigate = useNavigate();

    //     let { clientId, selectedTab } = useParams();

    //     const {settings} = useContext(GlobalContext);

    //     const [client, setClient] = useState(new Client());
    //     const [clientSettings, setClientSettings] = useState(new ClientSettings());

    //     const [name, setName] = useState("");
    //     const [street, setStreet] = useState("");
    //     const [city, setCity] = useState("");

    //     const [isEnabled, setIsEnabled] = useState(true);
    //     const [numberOfLicenses, setNumberOfLicenses] = useState(0);
    //     const [customPricePerLicense, setCustomPricePerLicense] = useState(65); // only used to set individial prices for a client
    //     const [hasCustomPricePerLicense, setHasCustomPricePerLicense] = useState(false);

    //     const [hasFeatureRecaller, setHasFeatureRecaller] = useState(false);
    //     const [hasFeatureSmsCockpit, setHasFeatureSmsCockpit] = useState(false);
    //     const [hasFeatureStaffRostering, setHasFeatureStaffRostering] = useState(false);
    //     const [hasFeatureVacationScheduler, setHasFeatureVacationScheduler] = useState(false);

    //     const [monthlyIncome, setMonthlyIncome] = useState(0);

    //     const [users, setUsers] = useState<User[]>([]);

    //     const tableSettings = {
    //         columns: [
    //             {propertyName: "lastName", caption: "Nachname"},
    //             {propertyName: "firstName", caption: "Vorname"}
    //         ],
    //         hasDeleteColumn: true
    //     }

    //     const [isLoading, setIsLoading] = useState(false);

    //     useEffect(()=>{

    //         loadData();

    //         // eslint-disable-next-line
    //     }, [clientId]);

    //     useEffect(() => {

    //         if(settings) {

    //             let pricePerLicense = hasCustomPricePerLicense ? customPricePerLicense : settings.pricePerLicense;

    //             pricePerLicense += hasFeatureRecaller ? settings.features.recallerPrice : 0;
    //             pricePerLicense += hasFeatureSmsCockpit ? settings.features.smsCockpitPrice : 0;
    //             pricePerLicense += hasFeatureStaffRostering ? settings.features.staffRosteringPrice : 0;
    //             pricePerLicense += hasFeatureVacationScheduler ? settings.features.vacationSchedulerPrice : 0;

    //             let income = numberOfLicenses * pricePerLicense;
    //             setMonthlyIncome(income);
    //         }

    //     }, [settings, hasCustomPricePerLicense, customPricePerLicense, numberOfLicenses, hasFeatureVacationScheduler, hasFeatureSmsCockpit, hasFeatureRecaller, hasFeatureStaffRostering]);

    //     async function loadData() {

    //         if(!clientId) return;

    //         if(clientId && clientId.toLowerCase() === "create"){
    //             setClient(new Client());

    //             setClientSettings(new ClientSettings());

    //         } else {

    //             setIsLoading(true);

    //             try {


    //                 const result = await ClientsService.getClient(clientId);

    //                 setIsLoading(false);

    //                 if(result) {

    //                     setClient(result);

    //                     setName(result.name ? result.name : "");
    //                     setStreet(result.street ? result.street : "");
    //                     setCity(result.city ? result.city : "");

    //                     setIsEnabled(result.isEnabled);

    //                     setHasFeatureRecaller(result.features.hasRecaller ? result.features.hasRecaller : false);
    //                     setHasFeatureSmsCockpit(result.features.hasSmsCockpit ? result.features.hasSmsCockpit : false);
    //                     setHasFeatureStaffRostering(result.features.hasStaffRostering ? result.features.hasStaffRostering : false);
    //                     setHasFeatureVacationScheduler(result.features.hasVacationScheduler ? result.features.hasVacationScheduler : false);

    //                     if(result.userIds){
    //                         setIsLoading(true);

    //                         const userResults = await UsersService.getUsers(result.id);

    //                         setIsLoading(false);

    //                         if(userResults){
    //                             const tempUsers: User[] = [];
    //                             userResults.forEach(element => {
    //                                 if(element){
    //                                     tempUsers.push(element);
    //                                 }
    //                             });
    //                             setUsers(tempUsers);
    //                         }
    //                     }

    //                 }


    //                 setIsLoading(true);
    //                 const settingsResult = await ClientsService.getClientSystemSettings(clientId);
    //                 setIsLoading(false);

    //                 if(settingsResult) {
    //                     setClientSettings(settingsResult);

    //                     setNumberOfLicenses( settingsResult.numberOfLicenses ? settingsResult.numberOfLicenses : 0);
    //                     setCustomPricePerLicense(settingsResult.customPricePerLicense ? settingsResult.customPricePerLicense : 80);
    //                     setHasCustomPricePerLicense(settingsResult.hasCustomPricePerLicense ? settingsResult.hasCustomPricePerLicense : false);
    //                 }

    //             } catch(error) {
    //                 console.log(error);
    //                 setIsLoading(false);
    //             }
    //         }
    //     }

    //     async function handleSubmit(event) {
    //         event.preventDefault();

    //         client.name = name;
    //         client.street = street;
    //         client.city = city;

    //         client.isEnabled = isEnabled;

    //         client.features.hasRecaller = hasFeatureRecaller;
    //         client.features.hasSmsCockpit = hasFeatureSmsCockpit;
    //         client.features.hasStaffRostering = hasFeatureStaffRostering;
    //         client.features.hasVacationScheduler = hasFeatureVacationScheduler;

    //         clientSettings.numberOfLicenses = numberOfLicenses;
    //         clientSettings.customPricePerLicense = customPricePerLicense;
    //         clientSettings.hasCustomPricePerLicense = hasCustomPricePerLicense;

    //         const clientId = await ClientsService.updateClient(client);
    //         if(clientId){
    //             await ClientsService.updateClientSystemSettings(clientId, clientSettings);
    //             navigate("/administration/clients");
    //         }

    //     }

    //     function onRowClick(row){
    //         navigate(`/administration/user/${clientId}/${row.id}`);
    //     }

    //     async function onDeleteRowClick(row) {
    //         if(row){
    //             const result = window.confirm(`Wollen Sie den Benutzer ${row.lastName} wirklich löschen?`);
    //             if (result && clientId) {
    //                 await UsersService.deleteUser(row.id, clientId);
    //                 const filteredUsers = users.filter(u => u.id !== row.id);
    //                 setUsers(filteredUsers);
    //             }
    //         }
    //     }


    //     if(isLoading) return <LoadingPage/>;

    //     if((!client && clientId !== "create") || !settings) return (
    //         <div className="kt-page-content">
    //             <h2>Kunde</h2>

    //             <div>Dieser Kunde existiert nicht. Bitte überprüfen Sie Ihre URL.</div>
    //         </div>
    //     );

    //     return (
    //         <div className="kt-page-content">
    //             <h2>Kunde - {name}</h2>

    //             <div className="kt-tab-buttons">
    //                 <Link to={`/administration/client/${clientId}`} className={!selectedTab ? "kt-selected" : ""}>Stammdaten</Link>
    //                 <Link to={`/administration/client/${clientId}/users`}  className={selectedTab==="users" ? "kt-selected" : ""}>Benutzer</Link>
    //             </div>

    //             {!selectedTab && <div className="kt-tab-content">
    //                 <form onSubmit={handleSubmit} autoComplete="turnitoff">

    //                     <div className="kt-form-group">
    //                         <h3>Standort</h3>

    //                         <div className="kt-input-control">
    //                             <label>Name</label>
    //                             <input type="text" name="name" value={name} onChange={e => setName(e.target.value)} required/>
    //                         </div>

    //                         <div className="kt-input-control">
    //                             <label>Straße</label>
    //                             <input type="text" name="street" value={street} onChange={e => setStreet(e.target.value)} required/>
    //                         </div>

    //                         <div className="kt-input-control">
    //                             <label>Stadt</label>
    //                             <input type="text" name="city" value={city} onChange={e => setCity(e.target.value)} required/>
    //                         </div>

    //                     </div>

    //                     <div className="kt-form-group">
    //                         <h3>Lizenz</h3>

    //                         <div className="kt-input-control">
    //                             <label>Aktiv</label>
    //                             <input type="checkbox" name="isEnabled" checked={isEnabled} onChange={e => setIsEnabled(e.target.checked)} />
    //                         </div>

    //                         <div className="kt-input-control">
    //                             <label>Anzahl Lizenzen</label>
    //                             <input type="number" name="numberOfLicenses" value={numberOfLicenses} onChange={e => setNumberOfLicenses(parseInt(e.target.value))} min="0" />
    //                         </div>

    //                         <div className="kt-input-control">
    //                             <label>Individual Preis</label>
    //                             <input type="checkbox" name="hasCustomPricePerLicense" checked={hasCustomPricePerLicense} onChange={e => setHasCustomPricePerLicense(e.target.checked)} />
    //                         </div>

    //                         {hasCustomPricePerLicense ?
    //                             <div className="kt-input-control">
    //                                 <label>Preis pro Lizenz</label>
    //                                 <input type="number" name="customPricePerLicense" value={customPricePerLicense} onChange={e => setCustomPricePerLicense(parseInt(e.target.value))} min="0"/> €
    //                             </div>
    //                             :
    //                             <div className="kt-input-control">
    //                                 <label>Preis pro Lizenz {settings.pricePerLicense} €</label>
    //                             </div>
    //                         }


    //                     </div>

    //                     <div className="kt-form-group">
    //                         <h3>Features</h3>

    //                         <div className="kt-input-control">
    //                             <label>Recaller {settings.features.recallerPrice} €</label>
    //                             <input type="checkbox" name="hasFeatureRecaller" checked={hasFeatureRecaller} onChange={e => setHasFeatureRecaller(e.target.checked)} />
    //                         </div>

    //                         <div className="kt-input-control">
    //                             <label>SMS Cockpit {settings.features.smsCockpitPrice} €</label>
    //                             <input type="checkbox" name="hasFeatureSmsCockpit" checked={hasFeatureSmsCockpit} onChange={e => setHasFeatureSmsCockpit(e.target.checked)} />
    //                         </div>

    //                         <div className="kt-input-control">
    //                             <label>Dienstplan {settings.features.staffRosteringPrice} €</label>
    //                             <input type="checkbox" name="hasFeatureStaffRostering" checked={hasFeatureStaffRostering} onChange={e => setHasFeatureStaffRostering(e.target.checked)} />
    //                         </div>

    //                         <div className="kt-input-control">
    //                             <label>Urlaubsplan {settings.features.vacationSchedulerPrice} €</label>
    //                             <input type="checkbox" name="hasFeatureVacationScheduler" checked={hasFeatureVacationScheduler} onChange={e => setHasFeatureVacationScheduler(e.target.checked)} />
    //                         </div>


    //                     </div>

    //                     <div className="kt-form-group">
    //                         <h3>Einnahmen (pro Monat / pro Jahr)</h3>

    //                         <div className="kt-input-control">
    //                             <label>Pro Lizenz</label>
    //                             <strong>{monthlyIncome / numberOfLicenses}€ / {(monthlyIncome / numberOfLicenses) * 12}€</strong>
    //                         </div>

    //                         <div className="kt-input-control">
    //                             <label>Gesamt</label>
    //                             <strong>{monthlyIncome}€ / {monthlyIncome * 12}€</strong>
    //                         </div>

    //                     </div>



    //                     <button type="submit" className="kt-btn">SPEICHERN</button>

    //                 </form>
    //             </div>}

    //             {selectedTab === "users" && <div className="kt-tab-content">
    //                 <div className="kt-button-bar">
    //                     <div className="kt-btn"><Link to={`/administration/user/${clientId}/create`}><i className="fal fa-plus-circle"></i> Benutzer anlegen</Link></div>
    //                 </div>
    //                 <DataTable rows={users} settings={tableSettings} onRowClick={onRowClick} onDeleteRowClick={onDeleteRowClick}/>
    //             </div>}

    //         </div>
    //     );

    return <>
    </>
}

export default ClientPage;