import { useState, useContext } from 'react';


import { Link, Navigate, useNavigate } from "react-router-dom";

import firebaseApp from "../database";
import { GlobalContext } from '../../GlobalContext';


function LoginPage() {


    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");

    const { setIsLoading } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);

    function onLoginFormSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        firebaseApp.auth().signInWithEmailAndPassword(loginEmail, loginPassword)
            .then(() => {
                console.log(`logged in with: ${loginEmail}`);
                setIsLoading(false);
            })
            .catch((error) => {
                var errorCode = error.code;

                switch (errorCode) {
                    case "auth/invalid-email":
                    case "auth/user-not-found":
                    case "auth/wrong-password":
                        alert("Die von Ihnen eingegebene E-Mail-Adresse oder das Passwort ist falsch. Bitte versuchen Sie es erneut");
                        break;

                    case "auth/user-disabled":
                        alert("Dieser Account ist gesperrt, bitte wenden Sie Sich telefonisch an uns.");
                        break;

                    default:
                        alert(error.message);
                        break;
                }

                setIsLoading(false);
            });
    }

    if (currentUser) {
        const targetPath = new URLSearchParams(window.location.search).get("target");

        if(targetPath){
            return <Navigate to={`${targetPath}`} replace />
        }

        return <Navigate to={`/`} replace />
    }

    return <div className="kt-login-page">

        <form name="login" onSubmit={onLoginFormSubmit}>

            <h1>Pickadoc</h1>

            <img src="/images/logo.svg" alt="logo" className='kt-logo' />

            <div className="kt-grid">
                <input type="email" placeholder="E-Mail-Adresse" required autoComplete="true" value={loginEmail} onChange={e => setLoginEmail(e.target.value)} />
                <input type="password" placeholder="Passwort" required autoComplete="true" value={loginPassword} onChange={e => setLoginPassword(e.target.value)} />
                <Link to={"/password-recovery"} className="kt-align-right">Passwort vergessen?</Link>
                <input className="kt-btn-big" type="submit" value="ANMELDEN" />
            </div>

        </form>

    </div>;
}

export default LoginPage;