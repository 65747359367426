import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";

import Patient from "../models/patient";
import Appointment, { PatientStatus } from "../models/appointment";
import { isGermanMobileNumber, logDbReadOperations, trimPhoneNumberWithoutCountryCode } from "../utils";
import UsersService from "./usersService";

import AppointmentsService from "./appointmentsService";

import Utils from "../../src/shared/src/utils/utils";
import { InsuranceFilter } from '../shared/src/models/campaign/campaign';
import DateUtils from '../shared/src/utils/dateUtils';
import CampaignPatient from '../models/campaignPatient';
import { DuplicateHandling, ImportedColumn } from '../components/dialogs/fileImporter';

const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');


const PatientsService = {

    geocoder: null as any,

    async getAllPatients(filter: string, searchTag: string, clientId: string, locationId: string): Promise<Patient[] | null> {

        console.log(`getAllPatients - filter: ${filter} - searchTag: ${searchTag} - clientId: ${clientId} - locationId: ${locationId}`);

        const patientList: Patient[] = [];

        try {

            let _filter = filter.toLowerCase().trim();
            _filter = _filter.replaceAll(',', ' ');

            let filters = _filter.split(" ");

            if(searchTag !== undefined && searchTag !== ""){
                filters = [searchTag];
            }

            let query = db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("patients")
                .where("searchIndexes", "array-contains", filters[0])
                .orderBy("lastName");

            if (filter === "***") {
                query = db.collection("clients")
                    .doc(clientId)
                    .collection("locations")
                    .doc(locationId)
                    .collection("patients")
                    .orderBy("lastName");
            }

            const querySnapshot = await query.get();

            querySnapshot.forEach((doc) => {

                const patient = new Patient();
                patient.fromObject(doc.id, doc.data());

                let addPatient = true;

                if (filters.length > 1) {

                    for (let f = 1; f < filters.length; f++) {
                        const filtr = filters[f];
                        if (filtr.trim().length > 1 && !patient.searchIndexes.includes(filtr)) addPatient = false;
                    }
                }

                if (addPatient) patientList.push(patient);

            });


            logDbReadOperations("getAllPatients", patientList.length);

            return patientList;

        } catch (error) {
            console.log("Error getting patients: ", error);
            return null;
        }

    },

    async getPatients(filter: string, clientId: string, locationId: string): Promise<Patient[] | null> {

        console.log(`getPatients - filter: ${filter}`);

        try {

            let _filter = filter.toLowerCase().trim();
            _filter = _filter.replaceAll(',', ' ');

            const filters = _filter.split(" ");

            const patientList: Patient[] = [];

            const querySnapshot = await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("patients")
                .where("searchIndexes", "array-contains", filters[0])
                .orderBy("lastName")
                .get();

            querySnapshot.forEach((doc) => {

                const patient = new Patient();
                patient.fromObject(doc.id, doc.data());

                let addPatient = true;

                if (filters.length > 1) {

                    for (let f = 1; f < filters.length; f++) {
                        const filtr = filters[f];
                        if (filtr.trim().length > 1 && !patient.searchIndexes.includes(filtr)) addPatient = false;
                    }
                }

                if (addPatient) patientList.push(patient);

            });

            logDbReadOperations("getPatients", patientList.length);

            return patientList;

        } catch (error) {
            console.log("Error getting patients: ", error);
            return null;
        }

    },

    async getPatientsForRecall(fromDate: Date | null, toDate: Date, insurance: InsuranceFilter, calendarFilterId: string, visitMotiveFilterId: string, searchTag: string, clientId: string, locationId: string): Promise<CampaignPatient[] | null> {

        console.time("getPatientsByLastAppointmentDate");

        const _fromDate = !DateUtils.isValidDate(fromDate) ? new Date(Date.UTC(1900, 0)) : fromDate;

        try {
            const patientList: CampaignPatient[] = [];

            let query: any = db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("patients")
                .orderBy("lastAppointmentDate")

            if (calendarFilterId === "" && visitMotiveFilterId === "") {
                // this is much faster and less results
                query = query.where("lastAppointmentDate", ">=", _fromDate)
                query = query.where("lastAppointmentDate", "<", toDate);
            }


            if (insurance !== InsuranceFilter.all) {
                query = query.where("privateInsurance", "==", insurance === InsuranceFilter.private);
            }

            if (searchTag !== "") {
                query = query.where("searchIndexes", "array-contains", searchTag);
            }

            const querySnapshot = await query.get();

            querySnapshot.forEach(async (doc) => {

                const patient = new CampaignPatient();
                patient.fromObject(doc.id, doc.data());

                patientList.push(patient);
            });

            // fix patient data from wrong import
            // for (let i = 0; i < patientList.length; i++) {
            //     const pat = patientList[i];

            //     await PatientsService.updatePatient(pat, clientId, locationId);

            //     console.log(`Update patient ${i+1} of ${patientList.length}`);
            // }

            logDbReadOperations("getPatientsByLastAppointmentDate", patientList.length);

            let result: CampaignPatient[] = [];

            const toDateInMs = toDate.getTime();

            if ((calendarFilterId && calendarFilterId !== "all") || (visitMotiveFilterId && visitMotiveFilterId !== "all")) {
                const appointments = await AppointmentsService.getAppointmentsByDate(_fromDate!, new Date(), clientId, locationId, [calendarFilterId], visitMotiveFilterId);

                if (appointments !== null) {

                    for (let p = 0; p < patientList.length; p++) {
                        const patient = patientList[p];

                        const patientAppointmentInThePast = appointments.find(a => (a.patient.id === patient.id && a.status === "confirmed" && a.start.getTime() < toDateInMs));
                        const patientAppointmentInTheFuture = appointments.find(a => (a.patient.id === patient.id && a.status === "confirmed" && a.start.getTime() > toDateInMs));

                        if (patientAppointmentInThePast && !patientAppointmentInTheFuture) {
                            result.push(patient);
                        }
                    }
                }

            } else {
                result = patientList;
            }

            console.timeEnd("getPatientsByLastAppointmentDate");

            return result;

        } catch (error) {
            console.log("Error getting patients by appointment date: ", error);
            return null;
        }

    },


    async getPatient(patientId: string, clientId: string, locationId: string): Promise<Patient | null> {

        try {
            const doc = await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("patients")
                .doc(patientId)
                .get();

            if (doc.exists) {
                const patient = new Patient();
                patient.fromObject(doc.id, doc.data());

                logDbReadOperations("getPatient", 1);

                return patient;

            } else {
                console.log("getPatient: No such document: " + patientId);
                return null;
            }

        } catch (error) {
            console.log("Error getting patient: ", error);
            return null
        }

    },


    async deletePatient(patientId: string, clientId: string, locationId: string): Promise<void> {

        try {
            await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("patients")
                .doc(patientId)
                .delete();

        } catch (error) {
            console.log("Error deleting patient: ", error);
        }
    },


    getFullAdress(patient: Patient): string | null {
        if (patient && patient.city && patient.street && patient.postalCode) {
            return `${patient.street}, ${patient.postalCode} ${patient.city}`;
        }

        return null;
    },

    // async getGeoPoint(patient: Patient): Promise<Geolocation | null> {

    //     return new Promise((resolve, reject) => {
    //         try {

    //             if (!PatientsService.geocoder) {
    //                 PatientsService.geocoder = new (window as any).google.maps.Geocoder();
    //             }

    //             const address = PatientsService.getFullAdress(patient);

    //             if (address && PatientsService.geocoder) {

    //                 PatientsService.geocoder.geocode({ 'address': address }, function (results, status) {
    //                     if (status === 'OK') {
    //                         resolve(results[0].geometry.location);
    //                     } else {
    //                         resolve(null);
    //                     }
    //                 });


    //             } else {
    //                 console.log("Error getting geo point");
    //                 resolve(null);
    //             }


    //         } catch (error) {
    //             console.log("Error getting geo point: ", error);
    //             resolve(null);
    //         }
    //     });


    // },

    async updatePatientAnonymous(patient: Patient, clientId: string, locationId: string): Promise<boolean> {
        try {

            const updatePatientAnonymous = functions.httpsCallable('updatePatientAnonymous');
            await updatePatientAnonymous(
                {
                    patient: patient.toJSON(false),
                    clientId: clientId,
                    locationId: locationId
                }
            );

            return true;

        } catch (error) {
            console.error(`error in updatePatientAnonymous: ${error}`);
            return false;
        }
    },

    // we have patiens on the db root which store patient data, that the patient himself has created
    // under clients/locations/patients we store the patients information which the doctor has created
    async updatePatient(patient: Patient, clientId: string, locationId: string): Promise<string | null> {

        try {

            // Get geoloaction from adress
            const geoPoint: any = null;//await PatientsService.getGeoPoint(patient);

            if (geoPoint) {
                patient.location = new firebase.firestore.GeoPoint(geoPoint.lat(), geoPoint.lng());
            } else {
                patient.location = new firebase.firestore.GeoPoint(0, 0);
            }

            Utils.addUniqueToArray(patient.clientIds, clientId);

            patient.updateSearchIndexes();

            if (patient.id) {
                // update existing patient
                await db.collection("clients")
                    .doc(clientId)
                    .collection("locations")
                    .doc(locationId)
                    .collection("patients")
                    .doc(patient.id)
                    .set(patient.toJSON(), { merge: true });

                await PatientsService.setPatientToOldPatientStatus(patient, clientId, locationId);

                return patient.id;

            } else {
                // create a new patient
                const result = await db.collection("clients")
                    .doc(clientId)
                    .collection("locations")
                    .doc(locationId)
                    .collection("patients").add(patient.toJSON());

                return result.id;
            }

        } catch (error) {
            console.log("Error could not update patient: ", error);
            return null;
        }

    },

    async setPatientToOldPatientStatus(patient: Patient, clientId: string, locationId: string): Promise<Patient> {

        try {

            if (patient.newPatient) {

                let setToOld = false;

                const appointments = await AppointmentsService.getAppointmentsByPatientId(patient.id, clientId, locationId);
                setToOld = (appointments !== null && appointments.length > 1);

                if (patient.importId && patient.importSource) {
                    setToOld = true;
                }

                if (setToOld) {
                    await db.collection("clients")
                        .doc(clientId)
                        .collection("locations")
                        .doc(locationId)
                        .collection("patients")
                        .doc(patient.id)
                        .update({
                            newPatient: false
                        });

                    patient.newPatient = false;
                } else {
                    patient.newPatient = true;
                }

            }

        } catch (error) {
            console.log("Error setting patient status to old patient: ", error);
        }

        return patient;

    },

    // updates 'lastAppointmentDate' and 'newPatient' property
    async updatePatientStatus(patient: Patient, clientId: string, locationId: string): Promise<void> {

        const isNewPatient = (patient.lastAppointmentDate === null || patient.lastAppointmentDate.getUTCFullYear() === 1900) && !patient.importId;

        // sets the last date the patient had an appointment
        let lastAppointmentDateHasChanged = false;

        const lastAppointment = await AppointmentsService.getLatestTreatedAppointmentByPatientId(patient.id, clientId, locationId);
        if (lastAppointment && lastAppointment.start !== patient.lastAppointmentDate) {
            lastAppointmentDateHasChanged = true;
            patient.lastAppointmentDate = lastAppointment.start;
        }

        if (lastAppointmentDateHasChanged || patient.newPatient !== isNewPatient) {
            // last appointment date has to be saved for each location
            await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("patients")
                .doc(patient.id)
                .update({
                    lastAppointmentDate: patient.lastAppointmentDate,
                    newPatient: isNewPatient
                });
        }
    },

    async addToPatientAppointmentList(patient: Patient, appointment: Appointment): Promise<void> {

        try {

            await PatientsService.updatePatientStatus(patient, appointment.clientId, appointment.locationId);


            const doctor = await UsersService.getUser(appointment.clientId, appointment.calendar.userId);
            let doctorFullName = "";

            if (doctor) {
                doctorFullName = doctor.getFullNameWithGender();
            }

            // now link the clients patient with the real patient user
            //
            // in case there is no user which is registered with that mobile number, we create a temporary patient
            // there we can store the appointments
            // when the user at some point creates an account with that mobile phone number, we copy over the appointments
            // to the new account and delete the temporary user
            let patientUserId = patient.mobilePhoneNumber ?? "";

            if (patient.mobilePhoneNumber && (!patient.uid || patient.uid === patient.mobilePhoneNumber)) {
                const patientUser = await PatientsService.getPatientUserByMobilePhoneNumber(patient.mobilePhoneNumber);

                if (patientUser) {
                    patientUserId = patientUser.id;

                    patient.uid = patientUserId;

                    // update the client patient
                    await PatientsService.updatePatient(patient, appointment.clientId, appointment.locationId);

                } else {

                    // create the temporary user patient with the mobile phone number as id
                    await PatientsService.createUserPatient(patient, appointment.clientId, appointment.locationId);
                }

            } else {
                patientUserId = patient.uid;
            }


            // save a short appointment summary to the db root patients
            // then we can show a list of appointments on the patient app
            // without having to fetch each appointment individually which is expensive
            await db.collection("patients")
                .doc(patientUserId)
                .collection("appointments")
                .doc(appointment.id)
                .set({
                    start: appointment.start,
                    doctorId: appointment.calendar.userId,
                    doctorFullName: doctorFullName,
                    calendarId: appointment.calendar.id,
                    visitMotiveId: appointment.visitMotive.id,
                    visitMotiveName: appointment.visitMotive.name,
                    nameForPatient: appointment.visitMotive.nameForPatient,
                    clientId: appointment.clientId,
                    locationId: appointment.locationId,
                    status: appointment.status
                });



        } catch (error) {
            console.log("Error adding appointment to patient appointments: ", error);
        }
    },

    async createUserPatient(patient: Patient, clientId: string, locationId: string) {
        try {

            const userPatient = {
                id: trimPhoneNumberWithoutCountryCode(patient.mobilePhoneNumber),
                firstName: patient.firstName,
                lastName: patient.lastName,
                gender: patient.gender,
                email: patient.email,
                mobilePhoneNumber: patient.mobilePhoneNumber,
                marketingAllowed: patient.marketingAllowed,
                reminderAllowed: patient.reminderAllowed,
                smsAllowed: patient.smsAllowed,
                emailAllowed: patient.emailAllowed,
                clientUserIds: {
                }
            };

            userPatient.clientUserIds[`${clientId}-${locationId}`] = patient.id;

            await db.collection("patients")
                .doc(userPatient.id)
                .set(userPatient);

        } catch (error) {
            console.log("Error in creating user patient: ", error);
        }
    },

    async startImport(clientId: string, locationId: string): Promise<boolean> {
        try {
            const startPatientsImport = functions.httpsCallable('startPatientsImport');
            const result = await startPatientsImport(
                {
                    clientId: clientId,
                    locationId: locationId
                }
            );

            return true;

        } catch (error) {
            console.error(`error in startImport: ${error}`);
            return false;
        }
    },

    async startCSVImport(clientId: string, locationId: string, importId: string, importedColumns: ImportedColumn[], importedRows: any[], duplicateHandling: DuplicateHandling): Promise<string> {
        try {
            const startPatientsCSVImport = functions.httpsCallable('startPatientsCSVImport');
            const result = await startPatientsCSVImport(
                {
                    clientId: clientId,
                    locationId: locationId,
                    importId: importId,
                    importedColumns: importedColumns,
                    importedRows: importedRows,
                    duplicateHandling: duplicateHandling
                }
            );

            return result.data;

        } catch (error) {
            console.error(`error in startCSVImport: ${error}`);
        }

        return "";
    },

    async startDeletePatientsImport(clientId: string, locationId: string): Promise<boolean> {
        try {
            const deletePatientsImport = functions.httpsCallable('deletePatientsImport');
            await deletePatientsImport(
                {
                    clientId: clientId,
                    locationId: locationId
                }
            );

            return true;

        } catch (error) {
            console.error(`error in deletePatientsImport: ${error}`);
            return false;
        }
    },



    async getPatientUser(patientId: string): Promise<Patient | null> {

        try {
            const doc = await db.collection("patients")
                .doc(patientId)
                .get();

            if (doc.exists) {
                const patient = new Patient();
                patient.fromObject(doc.id, doc.data());
                return patient;

            } else {
                console.log("getPatient: No such document: " + patientId);
                return null;
            }

        } catch (error) {
            console.log("Error getting patient: ", error);
            return null
        }

    },

    async getPatientUserByMobilePhoneNumber(mobilePhoneNumber: string): Promise<Patient | null> {

        try {
            const phoneNumber = trimPhoneNumberWithoutCountryCode(mobilePhoneNumber);

            const docSnap = await db.collection("patients")
                .where("mobilePhoneNumber", "==", phoneNumber)
                .get();

            if (docSnap.size > 0) {
                const doc = docSnap.docs[0];
                const patient = new Patient();
                patient.fromObject(doc.id, doc.data());
                return patient;

            } else {
                console.log("getPatientUserByMobilePhoneNumber: No such document: " + phoneNumber);
                return null;
            }

        } catch (error) {
            console.log("Error getting patient by mobile phone number: ", error);
            return null
        }

    },

    async updatePatientsIndexes(clientId: string, locationId: string): Promise<boolean> {

        try {

            console.log("Starting updating patients indexes...");

            const updatePatientsIndexes = functions.httpsCallable('updatePatientsIndexes');
            await updatePatientsIndexes(
                {
                    clientId: clientId,
                    locationId: locationId
                }
            );

            console.log("Finished updating patients indexes.");

            return true;

        } catch (error) {
            console.log("Error updating patients indexes: ", error);
            return false;
        }
    },

    async startPatientsExport(clientId: string, locationId: string): Promise<boolean> {
        try {
            const startPatientsExport = functions.httpsCallable('startPatientsExport');
            await startPatientsExport(
                {
                    clientId: clientId,
                    locationId: locationId
                }
            );

            return true;

        } catch (error) {
            console.error(`error in startPatientsExport: ${error}`);
            return false;
        }
    },

    async resetWrongLoginsCounter(patient: Patient, clientId: string, locationId: string) {

        try {

            await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("patients")
                .doc(patient.id)
                .update({
                    wrongLoginsCounter: 0
                });


        } catch (error) {
            console.log("Error in patient resetWrongLoginsCounter: ", error);
        }

    },

    async setPatientDocumentsSentStatus(patientId: string, clientId: string, locationId: string, documentsSent: boolean) {

        try {

            await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("patients")
                .doc(patientId)
                .update({
                    documentsSent: documentsSent
                });


        } catch (error) {
            console.log("Error in patient setPatientDocumentsSentStatus: ", error);
        }

    },

    getInvalidMobilePhoneNumberPatients(patients: CampaignPatient[]): CampaignPatient[] {
        let result: CampaignPatient[] = [];

        result = patients.filter(p => !isGermanMobileNumber(p.mobilePhoneNumber));

        return result;
    }

}

export default PatientsService;