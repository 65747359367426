
import FormItem, { Align, FormItemEnum, FormItemType } from "./formItem";


export class VideoFormItem extends FormItem {

    videoWidth: number = 400; // in pixel
    videoSource: string = "";
    videoAlign: Align = Align.center;

    constructor(rowId: string) {
        super(rowId);

        this.type = FormItemEnum.video;
        this.setLanguageProperty("de", "Videolink", "labels");
    }

    clone(): FormItemType {

        const c = new VideoFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        obj.videoWidth = this.videoWidth;
        obj.videoSource = this.videoSource;
        obj.videoAlign = this.videoAlign;

        return obj;
    }

    fromObject(id: string|null, o:any){
        super.fromObject(id, o);

        this.videoWidth = o.videoWidth ? parseInt(o.videoWidth) : 400;
        this.videoSource = o.videoSource ?? "";
        this.videoAlign = o.videoAlign !== undefined ? o.videoAlign : Align.center;
    }


}


export default VideoFormItem;