import React, { useContext, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import ClonRVoice from '../../../shared/src/models/clonR/clonRVoice';
import AudioRecorderCtrl from '../../audioRecorderCtrl';
import FileUploadsService from '../../../services/fileUploadsService';
import { GlobalContext } from '../../../GlobalContext';
import { StorageError, TaskState } from 'firebase/storage';
import ProgressDialog from '../progressDialog';
import ClonRVoicesService from '../../../services/clonRVoicesService';
import QRCode from 'react-qr-code';
import FileUploader from '../../fileUploader';
import MessageDialog from '../messageDialog';


interface Props {
    clonRVoice: ClonRVoice;
    visible: boolean;
    onSave: (newClonRVoice: ClonRVoice) => void;
    onCancel: () => void;
}

const ClonRVoiceDialog: React.FC<Props> = ({ clonRVoice, visible, onSave, onCancel }) => {

    const [newClonRVoice, setNewClonRVoice] = useState(new ClonRVoice());
    const [progress, setProgress] = useState(0);
    const [uploadState, setUploadState] = useState<TaskState>("paused");

    const [isGeneratingCustomVoice, setIsGeneratingCustomVoice] = useState(false);

    const [recordedFile, setRecordedFile] = useState<File | null>(null);

    const { currentUser } = useContext(GlobalContext);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {

        if (visible) {
            setNewClonRVoice(clonRVoice);
            setRecordedFile(null);
        }

    }, [visible]);

    useEffect(() => {

        window.addEventListener('keydown', listenKeyboard, true);

        // clean up
        return () => {
            window.removeEventListener('keydown', listenKeyboard, true);
        }

    }, []);

    function listenKeyboard(event) {
        if (event.keyCode === 27) {
            onCancel();
        }
    }


    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _voice = newClonRVoice.clone();
        _voice.name = e.currentTarget.value;

        setNewClonRVoice(_voice);
    }

    const handleCloseClick = () => {
        onCancel();
    }

    const handleSaveClick = async () => {

        if (recordedFile) {
            // start upload

            const targetPath = `clients/${currentUser.clientId}/clonRVoices`;
            const targetFileName = newClonRVoice.id;

            // start upload
            setProgress(0);
            const uploadTask = FileUploadsService.uploadFileWithProgress(recordedFile, targetPath, targetFileName, false, handleUploadStateChanged, handleUploadFinishedAndSave, handleUploadError)

        } else {

            const _voice = newClonRVoice.clone();

            setIsGeneratingCustomVoice(true);
            const voiceId = await ClonRVoicesService.addVoice(currentUser.clientId, newClonRVoice.id, newClonRVoice.name, newClonRVoice.audioFileExtension);
            
            _voice.customVoiceId = voiceId;
            console.log("customVoiceId: " + voiceId);

            setNewClonRVoice(_voice);

            setIsGeneratingCustomVoice(false);
            
            onSave(_voice);
        }

    }

    const handleUploadFinished = async (downloadUrl: string, fileExtension: string) => {
        const _voice = newClonRVoice.clone();
        _voice.audioUrl = downloadUrl;
        _voice.audioFileExtension = fileExtension;

        setNewClonRVoice(_voice);
        setUploadState("success");

        return _voice;
    }

    const handleUploadFinishedAndSave = async (downloadUrl: string, fileExtension: string) => {
        const _voice = await handleUploadFinished(downloadUrl, fileExtension);

        setIsGeneratingCustomVoice(true);
        const voiceId = await ClonRVoicesService.addVoice(currentUser.clientId, newClonRVoice.id, newClonRVoice.name, fileExtension);
        setIsGeneratingCustomVoice(false);

        _voice.customVoiceId = voiceId;
        console.log("customVoiceId: " + voiceId);

        onSave(_voice);
    }

    const handleUploadStateChanged = (progress: number, state: TaskState) => {
        setUploadState(state);
        setProgress(progress);
    }

    const handleUploadError = (error: StorageError) => {

    }


    const handleFinishedRecording = (audioUrl: string, videoBlob: Blob) => {
        const _voice = newClonRVoice.clone();
        _voice.audioUrl = audioUrl;
        setNewClonRVoice(_voice);

        const file = new File([videoBlob], "video.mp4", { type: "video/mp4" });
        setRecordedFile(file);

        // we upload the file only on save
    }

    return (
        <>
            <MessageDialog
                visible={isGeneratingCustomVoice}
                title='Stimme wird generiert...'
                message={<p>Ihre Stimme wird nun analysiert und durch unsere KI generiert. <i className='fal fa-spinner-third fa-spin'></i></p>}
                onClose={() => setIsGeneratingCustomVoice(false)}
                titleIconFaClassName='fal fa-waveform-path'
            />

            <ProgressDialog
                visible={uploadState === "running"}
                progress={progress}
                title='Ihre Datei wird hochgeladen'
                label='Bitte haben Sie einen Moment Geduld...'
            />

            <Dialog
                aria-labelledby="customized-dialog-title"
                open={visible}
                maxWidth="xl"
                disableEnforceFocus={true}
                fullWidth={true}
                fullScreen={fullScreen}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "center" }}
                >
                    {clonRVoice.id ? "Stimme" : "Neue Stimme erstellen"}
                    <TextField
                        value={newClonRVoice.name}
                        onChange={handleNameChange}
                        label="Name"
                        disabled={newClonRVoice.isSystem}
                    />
                </DialogTitle>

                <DialogContent dividers style={{ backgroundColor: "#24374e" }} >
                    <Paper style={{ padding: "10px", minHeight: "400px" }}>

                        {newClonRVoice.isSystem ?
                            <Box
                                height={"100%"}
                            >
                                <div style={{ textAlign: "center" }}>
                                    <div className='kt-voice-icon' style={{ margin: "20px auto" }}>
                                        <i className='fal fa-waveform-path'></i>
                                        <div className='kt-voice-name'>{newClonRVoice.name}</div>
                                    </div>
                                    <div className="audio-container">
                                        <audio src={newClonRVoice.audioUrl} controls></audio>
                                    </div>
                                </div>
                            </Box>
                            :
                            <Box
                                height={"100%"}
                            >

                                <span className='kt-secondary-color'>Sprechen Sie bitte nun folgenden Text:</span>
                                <p>
                                    Ich bin damit einverstanden, dass meine Stimme von Pickadoc geklont wird.
                                    Ich habe die volle Kontrolle über die Inhalte, die mit meiner Stimme generiert werden.
                                    Das Ziel besteht darin, mit ein paar Sekunden Audio synthetische Stimmen zu erzeugen, die genau wie ich klingen.
                                    Generierte Inhalte können für Videos, Videoavatare, Instagram, YouTube und vieles mehr verwendet werden.
                                </p>


                                <AudioRecorderCtrl
                                    audioUrl={newClonRVoice.audioUrl}
                                    onFinishedRecording={handleFinishedRecording}
                                />

                                <br />
                                
                                <Box textAlign={"center"}>
                                    <FileUploader
                                        targetFileName={newClonRVoice.id}
                                        buttonLabel={"Audiodatei hochladen"}
                                        targetPath={`clients/${currentUser.clientId}/clonRVoices`}
                                        showProgressDialog={true}
                                        onUploadFinished={handleUploadFinished}
                                        maxFileSizeMB={100}
                                        mimeType='audio'
                                        generateVideoThumbnail={false}
                                    />
                                </Box>

                                {!newClonRVoice.audioUrl &&
                                    <Box sx={{ display: { xs: 'none', lg: 'block' } }} textAlign={"center"}>
                                        <p>
                                            Sie können auch Ihr Smartphone benutzen um ein Audio von sich aufzunehmen.
                                            <br />
                                            Scannen Sie dazu einfach diesen QR-Code:
                                        </p>
                                        <QRCode
                                            value="https://cal.pickadoc.de/clonr/voice"
                                            size={120}
                                        />
                                    </Box>
                                }
                            </Box>
                        }


                    </Paper>

                </DialogContent>

                {newClonRVoice.isSystem ?
                    <DialogActions>
                        <Button onClick={handleCloseClick} color="primary" variant='outlined'>Ok</Button>
                    </DialogActions>
                    :
                    <DialogActions>
                        <Button onClick={handleCloseClick} color="secondary" variant='outlined'>Abbrechen</Button>
                        <Button onClick={handleSaveClick} autoFocus color="primary" variant='outlined'
                            disabled={newClonRVoice.name.length === 0 || newClonRVoice.audioUrl === ""}
                        >
                            Speichern
                        </Button>
                    </DialogActions>
                }


            </Dialog >

        </>

    );
}

export default ClonRVoiceDialog;
