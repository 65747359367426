import database from "./../components/database";

import Client from "./../models/client";
import ClientSettings from "./../models/clientSettings";

const db = database.firestore();

const ClientsService = {

    cache: {} as {[key: string]: Client},

    async getAllClients():Promise<Client[] | null> {

		try {
            let clientList:Client[] = [];
            
            const querySnapshot = await db.collection("clients").orderBy("name").get();

            if(querySnapshot) {
                querySnapshot.forEach((doc) => {
                    const client = new Client();
                    client.fromObject(doc.id, doc.data());

                    client.id = doc.id;
                    //client.numberOfUsers = client.userIds ? client.userIds.length : 0;
                    clientList.push(client);

                    // update cache
                    ClientsService.cache[client.id] = client;
                });
            }

            return clientList;

        } catch(error) {
            console.log("Error getting clients: ", error);
            return null;
        };

    },

    async getClient(clientId: string, ignoreCache: boolean = false): Promise<Client | null> {

        try{

            if(!clientId){
                return null;            
            }
            
            if(!ignoreCache && ClientsService.cache[clientId]){
                return ClientsService.cache[clientId];
            }

            const doc = await db.collection("clients").doc(clientId).get();

            if (doc.exists) {
                const client = new Client();
                client.fromObject(clientId, doc.data());

                // update cache
                ClientsService.cache[client.id] = client;

                return client;

            } else {
                console.log("getClient: No such document: " + clientId);
                return null;
            }

        } catch(error) {
            console.log("Error getting client: ", error);
            return null;
        }

    },

    async getClientSystemSettings(clientId: string): Promise<ClientSettings | null> {

        try{
            const doc = await db.collection("clients").doc(`${clientId}/settings/system`).get();

            if (doc.exists) {
                const cs = new ClientSettings();
                cs.fromObject(doc.data());
                return cs;
                
            } else {
                console.log("getClientSettings: No such document: " + clientId);
                return new ClientSettings();
            }

        } catch(error) {
            console.log("Error getting client system settings: ", error);
            return null;
        }
            
    },

    // async getClientNotificationsSettings(clientId: string): Promise<NotificationsSettings | null> {

    //     try{
    //         const doc = await db.collection("clients").doc(`${clientId}/settings/notifications`).get();

    //         if (doc.exists) {
    //             const cs = new NotificationsSettings();
    //             cs.fromObject(doc.data());
    //             return cs;
                
    //         } else {
    //             console.log("getNotificationsSettings: No such document: " + clientId);
    //             return new NotificationsSettings();
    //         }

    //     } catch(error) {
    //         console.log("Error getting client notifications settings: ", error);
    //         return null;
    //     }
            
    // },

   

    async updateClient(client: Client): Promise<string | null> {

        if(client.id) {
            
            try {
                
                // update existing client
                await db.collection("clients").doc(client.id).set(client.toJSON(), { merge: true });

                // update cache
                ClientsService.cache[client.id] = client;

                return client.id;

            } catch(error) {            
                console.log("Error updating client: ", error);
                return null;
            };

        } else {
            try {
                // create a new client
                const result = await db.collection("clients").add(client.toJSON())

                // update cache
                ClientsService.cache[client.id] = client;
                
                return result.id;

            } catch(error) {
                console.log("Error creating new client: ", error);
                return null;
            };
        }
        
    },

   async updateClientSystemSettings(clientId: string, clientSettings: ClientSettings) : Promise<void>{

        try {

            await db.collection("clients").doc(`${clientId}/settings/system`).set(clientSettings.toJSON(), { merge: true });
                
        } catch(error) {
            console.log("Error updating client settings: ", error);
        };
    },

    async deleteClient(clientId: string): Promise<void> {

        try {
            // ToDo: remove subcollections, add logs for deletion
            await db.collection("clients").doc(clientId).delete();
                
        } catch(error) {
            console.log("Error deleting client: ", error);
        };
    }
    
}

export default ClientsService;