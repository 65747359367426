import DateUtils from "../../src/shared/src/utils/dateUtils";
import { isGermanMobileNumber, trimPhoneNumber } from "../utils";
import Gender from "./gender";

export type PatientScore = 1|2|3|4|5;

class Patient {
    id: string = "";
    uid: string = ""; // from auth user

    importId: string = "";      // from PMS like DampSoft
    importSource: string = "";

    title: string = "";
    firstName: string = "";
    lastName: string = "";
    birthName: string = "";
    gender: Gender = Gender.female;
    newPatient: boolean = true;
    city: string = "";
    postalCode: string = "";
    street: string = "";
    appointments: string[] = [];
    phoneNumber: string = "";
    mobilePhoneNumber: string = "";
    email: string = "";
    comments: string = "";
    birthDate: Date | null = null;
    smsAllowed: boolean = true;
    emailAllowed: boolean = true;
    privateInsurance: boolean = false;
    clientIds: string[] = [];
    profession: string = "";
    familyDoctorName: string = "";
    familyDoctorNameCity: string = "";
    reminderAllowed: boolean = true;
    marketingAllowed: boolean = true;
    location: any = null; // geopoint

    lastAppointmentDate: Date | null = new Date(Date.UTC(1900,0));

    searchIndexes: string[] = [];

    lastWrongLoginDate: Date | null = null;
    wrongLoginsCounter: number = 0;

    documentsExpireAt: Date | null = new Date();

    documentsSent: boolean = false;

    score: PatientScore = 5;

    tags: string[] = []; // used as search tags

    createdAt: Date | null = new Date();

    clone(): Patient {
        const p = new Patient();

        p.id = this.id;
        p.uid = this.uid;

        p.importId = this.importId;
        p.importSource = this.importSource;

        p.title = this.title;
        p.firstName = this.firstName;
        p.lastName = this.lastName;
        p.birthName = this.birthName;
        p.gender = this.gender;
        p.newPatient = this.newPatient;
        p.city = this.city;
        p.postalCode = this.postalCode;
        p.street = this.street;
        p.appointments = [...this.appointments];
        p.phoneNumber = this.phoneNumber;
        p.mobilePhoneNumber= this.mobilePhoneNumber;
        p.email = this.email;
        p.comments = this.comments;
        p.birthDate = this.birthDate ? new Date(this.birthDate) : null;
        p.smsAllowed = this.smsAllowed;
        p.emailAllowed = this.emailAllowed;
        p.privateInsurance = this.privateInsurance;
        p.clientIds = [...this.clientIds];
        p.profession = this.profession;
        p.familyDoctorName = this.familyDoctorName;
        p.familyDoctorNameCity = this.familyDoctorNameCity;
        p.reminderAllowed = this.reminderAllowed;
        p.marketingAllowed = this.marketingAllowed;
        p.location = this.location;

        p.lastAppointmentDate = this.lastAppointmentDate ? new Date(this.lastAppointmentDate) : new Date(Date.UTC(1900,0));

        p.searchIndexes = [...this.searchIndexes];

        p.lastWrongLoginDate = this.lastWrongLoginDate ? new Date(this.lastWrongLoginDate) : null;
        p.wrongLoginsCounter = this.wrongLoginsCounter;

        p.documentsExpireAt = this.documentsExpireAt;
        p.documentsSent = this.documentsSent;

        p.score = this.score;

        p.tags = [...this.tags];

        p.createdAt = this.createdAt;

        return p;
    }

     // if we store directly to firestore use date
    // if we send json to cloud functions we need to use getTime()
    getTimeOrDate(value: Date | null, useDate: boolean) {
        if(useDate) {
            return value ?? null;
        } else {
            return value ? value.getTime() : null;
        }
    }


    toJSON(useToDate: boolean = true): object {

        const obj: any = {
            id: this.id,
            uid: this.uid,

            importId: this.importId,
            importSource: this.importSource,

            title: this.title,
            firstName: this.firstName,
            lastName: this.lastName,
            birthName: this.birthName,
            gender: this.gender,
            newPatient: this.newPatient === true,
            city: this.city,
            postalCode: this.postalCode,
            street: this.street,
            appointments: this.appointments,
            phoneNumber: this.phoneNumber,
            mobilePhoneNumber: this.mobilePhoneNumber,
            email: this.email,
            comments: this.comments,
            birthDate: this.birthDate ? DateUtils.toDateSecondsWithoutTimezoneInfo(this.birthDate) : null,
            smsAllowed: this.smsAllowed === true,
            emailAllowed: this.emailAllowed === true,
            privateInsurance: this.privateInsurance === true,
            clientIds: this.clientIds,
            profession: this.profession,
            familyDoctorName: this.familyDoctorName,
            familyDoctorNameCity: this.familyDoctorNameCity,
            reminderAllowed: this.reminderAllowed === true,
            marketingAllowed: this.marketingAllowed === true,
            lastAppointmentDate: this.lastAppointmentDate,

            searchIndexes: this.searchIndexes,

            lastWrongLoginDate: this.lastWrongLoginDate,
            wrongLoginsCounter: this.wrongLoginsCounter,

            score: this.score,

            tags: this.tags,

            createdAt: this.createdAt

            // don't save documents states from the client side
            // do this only on server side
            // documentsExpireAt: this.getTimeOrDate(this.documentsExpireAt, useToDate),
            // documentsSent: this.documentsSent
        }

        if(this.location){
            obj.location = this.location;
        }

        return obj;

    }

    fromObject(id:string|null, o:any) {

        this.id = id ?? o.id;
        this.uid = o.uid ?? "";

        this.importId = o.importId ?? "";
        this.importSource = o.importSource ?? "";

        this.title = o.title ?? "";
        this.firstName = o.firstName ?? "";
        this.lastName = o.lastName ?? "";
        this.birthName = o.birthName ?? "";
        this.gender = o.gender ?? Gender.female;
        this.newPatient = o.newPatient === true;
        this.city = o.city ?? "";
        this.postalCode = o.postalCode ?? "";
        this.street = o.street ?? "";
        this.appointments = o.appointments ?? [];
        this.phoneNumber = o.phoneNumber ?? "";
        this.mobilePhoneNumber = o.mobilePhoneNumber ?? "";
        this.email = o.email ?? "";
        this.comments = o.comments ?? "";
        this.birthDate = DateUtils.getDate(o.birthDate);
        this.smsAllowed = o.smsAllowed === true;
        this.emailAllowed = o.emailAllowed === true;
        this.privateInsurance = o.privateInsurance === true || o.privateInsurance === "true";
        this.clientIds = o.clientIds ?? [];
        this.profession = o.profession ?? "";
        this.familyDoctorName = o.familyDoctorName ?? "";
        this.familyDoctorNameCity = o.familyDoctorNameCity ?? "";
        this.reminderAllowed = o.reminderAllowed === true;
        this.marketingAllowed = o.marketingAllowed === true;
        this.location = o.location;

        this.lastAppointmentDate = o.lastAppointmentDate ? (DateUtils.getDate(o.lastAppointmentDate) ?? new Date()) : new Date(Date.UTC(1900,0));

        this.searchIndexes = o.searchIndexes ?? [];

        if((this.importId && this.importSource) || this.appointments.length > 1){
            this.newPatient = false;
        }

        this.lastWrongLoginDate = DateUtils.getDate(o.lastWrongLoginDate);
        this.wrongLoginsCounter = o.wrongLoginsCounter ?? 0;

        this.documentsExpireAt = o.documentsExpireAt ? DateUtils.getDate(o.documentsExpireAt) : new Date();
        this.documentsSent = o.documentsSent === true;

        this.score = o.score === undefined ? 5 : o.score;

        this.tags = o.tags ?? [];

        this.createdAt = DateUtils.getDate(o.createdAt);
    }

    getMobileOrPhoneNumber(){
        if(this.mobilePhoneNumber === "000") return this.phoneNumber;

        return this.mobilePhoneNumber ? this.mobilePhoneNumber : this.phoneNumber;
    }

    getGenderAndFullName(): string {
        let result = `${this.firstName} ${this.lastName}`;

        if(this.title && this.title.length > 1){
            result = `${this.title} ${result}`;
        }

        if(this.gender === Gender.female) {
            result = `Sehr geehrte Frau ${result}`;
        } else {
            result = `Sehr geehrter Herr ${result}`;
        }

        return result;
    }

    getGenderAndFullNameShort(): string {
        let result = `${this.firstName} ${this.lastName}`;

        if(this.title && this.title.length > 1){
            result = `${this.title} ${result}`;
        }

        if(this.gender === Gender.female) {
            result = `Frau ${result}`;
        } else {
            result = `Herr ${result}`;
        }

        return result;
    }

    updateSearchIndexes(){

        this.searchIndexes = [];

        // firstName indexes
        this.searchIndexes = this.searchIndexes.concat(this.getTruncatedStartStrings(this.firstName, 2));

        // lastName indexes
        this.searchIndexes = this.searchIndexes.concat(this.getTruncatedStartStrings(this.lastName, 2));

        // phoneNumber indexes
        let _phone = trimPhoneNumber(this.phoneNumber);
        _phone = _phone.replace("+49", "0");
        this.searchIndexes = this.searchIndexes.concat(this.getTruncatedStartStrings(_phone, 5));

        // mobilePhoneNumber indexes
        // country code is removed
        let _mobilePhone = trimPhoneNumber(this.mobilePhoneNumber);
        _mobilePhone = _mobilePhone.replace("+49", "0");
        this.searchIndexes = this.searchIndexes.concat(this.getTruncatedStartStrings(_mobilePhone, 5));

        // birthdate index
        if(this.birthDate) {
            let birthDateString = DateUtils.getDateString(this.birthDate);

            // in format 05.11.1978
            this.searchIndexes.push(birthDateString);

            // and in format 05111978
            birthDateString = birthDateString.replace(".", "").replace(".", "");
            this.searchIndexes.push(birthDateString);
        }

        // pms import id index
        if(this.importId){
            this.searchIndexes.push(this.importId);
        }

        this.searchIndexes = this.searchIndexes.concat(this.tags);

        //console.log(JSON.stringify(this.searchIndexes));
    }

    // creates truncted strings out of an input string,
    // where each string length is truncated by 1 more than the last created string
    // strings are truncated from the end of the string
    // example:
    // input: "Mustermann", length: 2
    // output: ["mustermann", "musterman", "musterma", "musterm", "muster", "muste", "must", "mus", "mu", ]
    getTruncatedStartStrings(input: string, length: number): string[] {

        const result: string[] = [];

        if(!input || length < 0){
            return result;
        }

        // make lower case and remova ALL whitespaces
        const _input = input.toLowerCase().replace(/\s+/g, '');

        if(_input.length > length){
            const iterations = (_input.length+1) - length;
            for (let i = 0; i < iterations; i++) {
                const truncatedString = _input.slice(0, _input.length-i);

                result.push(truncatedString);
            }

        } else {
            result.push(_input);
        }

        return result;
    }

    allDocumentsComplete(): boolean {
        const today = new Date();
        return this.documentsExpireAt === null || this.documentsExpireAt.getTime() > today.getTime();
    }

    hasValidMobileNumber() {
        return isGermanMobileNumber(this.mobilePhoneNumber);
    }

    canSendSms() {
        return isGermanMobileNumber(this.mobilePhoneNumber) && this.smsAllowed;
    }

};

export default Patient;