import { useContext } from "react";

import { Link } from "react-router-dom";

import { GlobalContext } from "../../GlobalContext";


interface Props {
    location?: string;
}


const SettingsSideMenu: React.FC<Props> = ({ location }) => {

    const {isSidebarVisible} = useContext(GlobalContext);
    const { currentLocation } = useContext(GlobalContext);


    function getMenuClassName(currentLocation: string | undefined, locationToCheck: string) {
        return currentLocation === locationToCheck ? "kt-side-menu-item kt-selected" : "kt-side-menu-item";
    }

    return (
        <div className={isSidebarVisible ? "kt-side-menu noselect" : "kt-side-menu kt-hide-on-mobile noselect"}>
            <Link to="/settings/clientlocations" className={getMenuClassName(location, "clientlocations")}><i className="fal fa-hospital" aria-hidden="true"></i> Standorte</Link>
            <Link to="/settings/rooms" className={getMenuClassName(location, "rooms")}><i className="fal fa-door-closed" aria-hidden="true"></i> Räume</Link>
            <Link to="/settings/devices" className={getMenuClassName(location, "devices")}><i className="fal fa-pager" aria-hidden="true"></i> Ressourcen</Link>
            <Link to="/settings/users" className={getMenuClassName(location, "users")}><i className="fal fa-users" aria-hidden="true"></i> Mitarbeiter</Link>
            <Link to="/settings/calendars" className={getMenuClassName(location, "calendars")}><i className="fal fa-calendar-alt" aria-hidden="true"></i> Kalender</Link>
            {/* <Link to="/settings/export" className={getMenuClassName(location, "export")}><i className="fal fa-file-export" aria-hidden="true"></i> Import/Export</Link> */}
            <Link to="/settings/specialities" className={getMenuClassName(location, "specialities")}><i className="fal fa-th-large" aria-hidden="true"></i> Besuchsgr. Gruppen</Link>
            <Link to="/settings/visitmotives" className={getMenuClassName(location, "visitmotives")}><i className="fal fa-stethoscope" aria-hidden="true"></i> Besuchsgründe</Link>
            {(currentLocation && currentLocation.features.hasDocuments) && <Link to="/settings/documents" className={getMenuClassName(location, "documents")}><i className="fal fa-paste" aria-hidden="true"></i> p-sign</Link>}
            {/* <Link to="/settings/ratings" className={getMenuClassName(location, "ratings")}><i className="fal fa-star" aria-hidden="true"></i> Bewertungen</Link> */}
            <Link to="/settings/notifications" className={getMenuClassName(location, "notifications")}><i className="fal fa-envelope" aria-hidden="true"></i> SMS</Link>
            {/* <Link to="/settings/invoice/current" className={getMenuClassName(location, "invoices")}><i className="fal fa-file-invoice-dollar" aria-hidden="true"></i> Rechnungen</Link> */}
            <Link to="/settings/events" className={getMenuClassName(location, "events")}><i className="fal fa-list-ul" aria-hidden="true"></i> Ereignisse</Link>
            <Link to="/clonr" className={getMenuClassName(location, "clonr")} ><i className="fal fa-video" aria-hidden="true"></i> Video ClonR <i style={{background: "none", color: "#d862c8"}} className="fa fa-stars"></i></Link>
            {/* <Link to="/settings/reminderreport" className="kt-side-menu-item"><i className="fal fa-file-chart-line" aria-hidden="true"></i> Sendungsbericht</Link> */}
        </div>
    )

}

export default SettingsSideMenu;