import Paper from '@mui/material/Paper';
import React from 'react';
import ClonRAvatar from '../../shared/src/models/clonR/clonRAvatar';


interface Props {
    className?: string,
    ownAvatars: ClonRAvatar[],
    publicAvatars: ClonRAvatar[],
    selectedClonRAvatar: ClonRAvatar,
    onSelectionChange: (clonRAvatar: ClonRAvatar) => void
}


const ClonRAvatarListCtrl: React.FC<Props> = ({className, ownAvatars, publicAvatars, selectedClonRAvatar, onSelectionChange}) => {

    return (
        <div className={`kt-clonr-avatar-list-ctrl ${className ?? ""}`}>
            <Paper className='kt-paper kt-video-avatar-list-wrapper'>
                    <h3 style={{ width: "100%" }}>Eigene VideoClones ({ownAvatars.length})</h3>
                    <div className='kt-video-avatar-list'>
                        {ownAvatars.map(avatar =>
                            <div key={avatar.id} onClick={() => onSelectionChange(avatar)}>
                                <img src={avatar.thumbnailUrl} className={avatar.id === selectedClonRAvatar.id ? "kt-selected" : ""} />
                            </div>
                        )}
                    </div>

                    <h3 style={{ width: "100%" }}>Bibliothek ({publicAvatars.length})</h3>
                    <div className='kt-video-avatar-list'>
                        {publicAvatars.map(avatar =>
                            <div key={avatar.id} onClick={() => onSelectionChange(avatar)}>
                                <img src={avatar.thumbnailUrl} className={avatar.id === selectedClonRAvatar.id ? "kt-selected" : ""} />
                            </div>
                        )}
                    </div>
                </Paper>
        </div>
    );
}

export default ClonRAvatarListCtrl;