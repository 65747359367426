import { useEffect } from 'react';

import firebaseApp from "./../database";


function LogoutPage() {

    useEffect(() => {
        firebaseApp.auth().signOut();
    }, [])

    return <></>;
}

export default LogoutPage;