import Paper from '@mui/material/Paper';
import React, { useContext, useEffect, useState } from 'react';
import ClonRAvatar from '../../shared/src/models/clonR/clonRAvatar';
import ConfirmDialog from '../dialogs/confirmDialog';

import { GlobalContext } from '../../GlobalContext';
import ClonRAvatarDialog from '../dialogs/clonRDialog/clonRAvatarDialog';
import ClonRAvatarsService from '../../services/clonRAvatarsService';
import EmailService from '../../services/emailService';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DateUtils from '../../shared/src/utils/dateUtils';
import Box from '@mui/material/Box';
import Email from '../../../src/shared/src/models/email';

const ClonRAvatarsCtrl: React.FC = () => {

    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const [isClonRAvatarDialogOpen, setIsClonRAvatarDialogOpen] = useState(false);

    const { currentUser } = useContext(GlobalContext);
    const { currentClient } = useContext(GlobalContext);

    const [ownAvatars, setOwnAvatars] = useState<ClonRAvatar[]>([]);
    const [publicAvatars, setPublicAvatars] = useState<ClonRAvatar[]>([]);
    const [selectedClonRAvatar, setSelectedClonRAvatar] = useState(new ClonRAvatar());

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        loadAvatars();
    }, []);

    const loadAvatars = async () => {
        const _avatars = await ClonRAvatarsService.getAvatars(currentUser.clientId);
        if (_avatars) {
            setOwnAvatars(_avatars.filter(a => a.isSystem === false));
            setPublicAvatars(_avatars.filter(a => a.isSystem === true));
        }
        setIsLoading(false);
    }

    const addNewAvatar = () => {
        setSelectedClonRAvatar(new ClonRAvatar());
        setIsClonRAvatarDialogOpen(true);
    }

    const handleSaveClonRAvatar = async (newClonRAvatar: ClonRAvatar) => {

        let sendEmail = false;

        // for new clones:
        if (newClonRAvatar.createdBy === "") {
            newClonRAvatar.createdBy = currentUser.id;
            newClonRAvatar.status = "inProgress";

            sendEmail = true;
        }

        const clonRAvatarId = await ClonRAvatarsService.updateAvatar(newClonRAvatar, currentUser.clientId);

        if (clonRAvatarId) {
            newClonRAvatar.id = clonRAvatarId;

            if (sendEmail) sendNewClonRAvatarEmail(newClonRAvatar);
        }


        setIsClonRAvatarDialogOpen(false);

        loadAvatars();
    }

    const sendNewClonRAvatarEmail = (newClonRAvatar: ClonRAvatar) => {
        const email = new Email();

        email.senderEmail = "info@pickadoc.de";
        email.senderName = "Pickadoc ClonR";

        email.subject = `Neuer ClonR Avatar zum Bearbeiten erstellt`;
        
        email.textBody = `User: ${currentUser.getFullName()} hat einen neuen ClonR Avatar erstellt.`;
        email.htmlBody = `<p style="font-size:16px">
                                Kunde: ${currentClient.name} - clientId: ${currentClient.id}<br/>
                                User: ${currentUser.getFullName()} - userId: ${currentUser.id}<br/>
                                Videoklon Name: ${newClonRAvatar.name} - id: ${newClonRAvatar.id}<br/>
                                Video:<br/><a href="${newClonRAvatar.videoUrl}">Videolink<br/><img src="${newClonRAvatar.thumbnailUrl}" width="300"/></a>
                            </p>`;

        email.toAddresses = ["info@pickadoc.de"];

        EmailService.sendEmail(email);

    }

    const handleOnClickAvatar = async (avatar: ClonRAvatar) => {
        setSelectedClonRAvatar(avatar);
        setIsClonRAvatarDialogOpen(true);
        console.log(avatar.id);
    }

    const handleDeleteAvatarClick = async (avatar: ClonRAvatar) => {
        setSelectedClonRAvatar(avatar);
        setIsConfirmDeleteDialogOpen(true);
    }

    const deleteAvatar = async () => {
        await ClonRAvatarsService.deleteAvatar(selectedClonRAvatar, currentUser.id, currentUser.clientId);

        setIsConfirmDeleteDialogOpen(false);
        setSelectedClonRAvatar(new ClonRAvatar());

        loadAvatars();
    }

    function AvatarCard(avatar: ClonRAvatar) {
        return (
            <Card sx={{ width: 260, height: 230 }} className='kt-video-card'>
                <div onClick={() => handleOnClickAvatar(avatar)}>
                {avatar.status === "error" &&
                        <>
                            <CardMedia
                                sx={{ height: 140, backgroundSize: "contain", opacity: 0.5 }}
                                image={avatar.thumbnailUrl}
                                title={avatar.name}
                            />
                            <div style={{ position: "relative", left: "100px", top: "-90px", height: 0, color:"red" }}><i className='far fa-ban' style={{ fontSize: "36px" }}></i></div>
                            <div className='kt-video-status'>
                                <div style={{ color: "red", backgroundColor: "lightred" }}><span><i className='far fa-exclamation-triangle'></i> Abgelehnt</span></div>
                            </div>
                        </>
                    }
                    {avatar.status === "inProgress" &&
                        <>
                            <CardMedia
                                sx={{ height: 140, backgroundSize: "contain", opacity: 0.5 }}
                                image={avatar.thumbnailUrl}
                                title={avatar.name}
                            />
                            <div style={{ position: "relative", left: "100px", top: "-90px", height: 0 }} className='kt-primary-color'><i className='far fa-spinner-third fa-spin' style={{ fontSize: "36px" }}></i></div>
                            <div className='kt-video-status'>
                                <div style={{ color: "grey", backgroundColor: "lightorange" }}><span><i className='far fa-sync-alt'></i> In Bearbeitung</span></div>
                            </div>
                        </>
                    }
                    {avatar.status === "completed" &&
                        <>
                            <CardMedia
                                sx={{ height: 140, backgroundSize: "contain" }}
                                image={avatar.thumbnailUrl}
                                title={avatar.name}
                            />
                            <div className='kt-video-play-overlay-button-wrapper' style={{ position: "relative", left: "100px", top: "-90px" }}>
                                <div className='kt-video-play-overlay-button'><i className='fas fa-play'></i></div>
                            </div>
                            {/* <div className='kt-video-status'><div><span><i className='far fa-video'></i> {avatar.length}s</span></div></div> */}
                        </>
                    }
                </div>
                {!avatar.isSystem && <div style={{ position: "relative", top: "50px", left: "220px", height: 0 }}>
                    <div onClick={() => handleDeleteAvatarClick(avatar)}><span><i className='far fa-trash'></i></span></div>
                </div>}
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        <strong>{avatar.name}</strong>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {!avatar.isSystem && <span style={{ fontSize: "12px" }}>{DateUtils.getDateTimeString(avatar.createdAt)}</span>}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <>
            <ConfirmDialog
                visible={isConfirmDeleteDialogOpen}
                title='Videoklon löschen?'
                label={`Wollen Sie den Videoklon ${selectedClonRAvatar.name} wirklich löschen?`}
                onReject={() => setIsConfirmDeleteDialogOpen(false)}
                onConfirm={deleteAvatar}
            />

            <ClonRAvatarDialog
                clonRAvatar={selectedClonRAvatar}
                visible={isClonRAvatarDialogOpen}
                onSave={handleSaveClonRAvatar}
                onCancel={() => setIsClonRAvatarDialogOpen(false)}
            />

            <Paper className="kt-paper">
                <div className='kt-paper-title'>VideoClones</div>

                <div className='kt-big-add-button' onClick={addNewAvatar}>
                    <img src="/images/plus-icon.png"></img>
                    <Typography variant="body2">
                        <strong>Neuen VideoClone erstellen</strong>
                    </Typography>
                </div>

                {(!isLoading && ownAvatars.length === 0) && <div>Es sind noch keine VideoClones vorhanden.</div>}
                {(isLoading) && <div className='kt-loading'>VideoClones werden geladen... <i className='fas fa-spinner-third fa-spin'></i></div>}

                <h3>Meine VideoClones ({ownAvatars.length})</h3>
                <Box display={"flex"} flexWrap={"wrap"} gap={1} className="kt-justify-content-center-mobile">
                    {ownAvatars.map(avat => <Box key={avat.id}>{AvatarCard(avat)}</Box>

                    )}
                </Box>

                <h3>Bibliothek ({publicAvatars.length})</h3>
                <Box display={"flex"} flexWrap={"wrap"} gap={1} className="kt-justify-content-center-mobile">
                    {publicAvatars.map(avat => <Box key={avat.id}>{AvatarCard(avat)}</Box>

                    )}
                </Box>

            </Paper>
        </>
    );
}

export default ClonRAvatarsCtrl;