import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import LoadingPage from "./../administration/loadingPage";
import Calendar, { CalendarLicense } from '../../../models/calendar';
import CalendarsService from "../../../services/calendarsService";
import LocationsDropDown from '../../locationsDropDown';
import ClientLocation from '../../../models/clientLocation';
import User from '../../../models/user';
import UsersService from '../../../services/usersService';
import SaveStatus from '../../../models/saveStatus';
import BackButton from '../../backButton';
import SaveButton from '../../saveButton';
import { GlobalContext } from '../../../GlobalContext';

function CalendarPage() {

    const navigate = useNavigate();

    let { calendarId } = useParams();

    const {settings} = useContext(GlobalContext);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);

    const [calendar, setCalendar] = useState(new Calendar());

    const [name, setName] = useState("");
    const [abbreviation, setAbbreviation] = useState("");
    const [users, setUsers] = useState<User[]>([]);
    const [allowOnlineAppointments, setAllowOnlineAppointments] = useState(false);
    const [internal, setInternal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const {calendars} = useContext(GlobalContext);
    const [saveStatus, setSaveStatus] = useState(SaveStatus.none);

    useEffect(() => {

        setSaveStatus(SaveStatus.none);

        loadData();

        // eslint-disable-next-line
    }, [calendarId]);


    async function loadData() {

        if (!currentUser) {
            return;
        }

        const _users = await UsersService.getUsersByLocationId(currentUser.clientId, currentUser.locationId);
        setUsers(_users);

        if (calendarId && calendarId.toLowerCase() === "create") {
            const newCalendar = new Calendar();
            newCalendar.clientId = currentClient.id;
            newCalendar.cardinality = calendars.length+1;
            setCalendar(newCalendar);
        } else {

            setIsLoading(true);

            try {

                if (currentUser && calendarId) {
                    const result = await CalendarsService.getCalendar(currentClient.id, currentUser.locationId, calendarId);

                    setIsLoading(false);

                    if (result) {

                        setCalendar(result);

                        setName(result.name ? result.name : "");

                        setAbbreviation(result.abbreviation);

                        setAllowOnlineAppointments(result.allowOnlineAppointments);

                        setInternal(result.internal);
                    }
                }


            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setSaveStatus(SaveStatus.isSaving);

        calendar.name = name;
        calendar.abbreviation = abbreviation;
        calendar.allowOnlineAppointments = allowOnlineAppointments;
        calendar.internal = internal;

        const _user = await UsersService.getUser(calendar.clientId, calendar.userId);
        if (_user) {
            calendar.avatarUrl = _user.avatarUrl;
        }

        const calendarId = await CalendarsService.updateCalendar(currentClient.id, calendar.locationId, calendar);

        setSaveStatus(SaveStatus.saved);

        if (calendarId) {
            navigate("/settings/calendars");
        }

    }

    function onLocationChange(newLocation: ClientLocation) {
        if (newLocation && calendar) {
            const c = calendar.clone();

            c.locationId = newLocation.id;
            c.locationName = newLocation.name;

            setCalendar(c);
        }
    }

    function onLicenseChange(newLicense: CalendarLicense) {
        if(newLicense && calendar) {
            const c = calendar.clone();

            c.license = newLicense;

            setCalendar(c);
        }
    }

    async function onUserChange(event) {
        if (event.target && calendar && currentUser) {

            const c = calendar.clone();

            c.userId = event.target.value;

            const _user = await UsersService.getUser(currentUser.clientId, c.userId);
            if (_user) {
                c.avatarUrl = _user.avatarUrl;
            }

            setCalendar(c);
        }
    }


    if (isLoading) return <LoadingPage />;

    if ((!calendar && calendarId !== "create") || !settings) return (
        <div className="kt-page-content">
            <h2>Terminkalender</h2>

            <div>Dieser Kalender existiert nicht. Bitte überprüfen Sie Ihre URL.</div>
        </div>
    );

    return (
        <Box className="kt-page">
            <h2>Terminkalender - {name}</h2>

            <form onSubmit={handleSubmit} autoComplete="turnitoff">

                <div className="kt-form-section">

                    <Grid container>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="name"
                                label="Name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <LocationsDropDown
                                fullWidth={true}
                                clientId={currentClient.id}
                                selectedId={calendar.locationId}
                                onChange={onLocationChange}
                                readOnly={calendar && calendar.id !== ""}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="doctorId-select-label">Benutzer</InputLabel>
                                <Select
                                    required
                                    disabled={false}
                                    name="doctorId"
                                    labelId="doctorId-select-label"
                                    label="Behandler"
                                    id="doctorId-select"
                                    value={calendar.userId}
                                    onChange={onUserChange}
                                >
                                    <MenuItem value="">Wählen Sie einen Benutzer aus</MenuItem>

                                    {users.map((doctor) =>
                                        <MenuItem key={doctor.id} value={doctor.id}>{`${doctor.firstName} ${doctor.lastName}`}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="abbreviation"
                                label="Kürzel"
                                value={abbreviation}
                                onChange={e => setAbbreviation(e.target.value)}                                
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox checked={allowOnlineAppointments} onChange={e => setAllowOnlineAppointments(e.target.checked)} name="allowOnlineAppointments" color="default" />}
                                label="Online-Terminbuchungen erlauben"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox checked={internal} onChange={e => setInternal(e.target.checked)} name="internal" color="default" />}
                                label="Interner Kalender (keine Patientenbenachrichtigungen)"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="doctorId-select-label">Lizenz</InputLabel>
                                <Select
                                    required
                                    disabled={false}
                                    name="license"
                                    labelId="licenseId-select-label"
                                    label="Lizenz"
                                    id="licenseId-select"
                                    value={calendar.license}
                                    onChange={(e) => onLicenseChange(e.target.value as CalendarLicense)}
                                >
                                    <MenuItem value="">Wählen Sie eine Lizenz für diesen Kalender aus</MenuItem>
                                    
                                    <MenuItem value="CalendR"><strong>CalendR</strong>&nbsp;- SMS Terminkalender</MenuItem>
                                    <MenuItem value="CalendR_Plus"><strong>CalendR Plus</strong>&nbsp;- Termine und Dokumente</MenuItem>
                                    <MenuItem value="CalendR_AI"><strong>CalendR AI</strong>&nbsp;- Termine und AI Videos</MenuItem>
                                    <MenuItem value="CalendR_AI_Plus"><strong>CalendR AI Plus</strong>&nbsp;- Termine, Dokumente und AI Videos</MenuItem>                                    

                                </Select>
                            </FormControl>

                        </Grid>

                    </Grid>

                </div>

                <div className="kt-page-footer">
                    <BackButton />

                    <SaveButton saveStatus={saveStatus} />

                </div>


            </form>

        </Box>
    );
}

export default CalendarPage;