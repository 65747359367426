import ClonRAvatar from "../shared/src/models/clonR/clonRAvatar";
import database from "../components/database";
import firebase from 'firebase/compat/app';
import FileUploadsService from "./fileUploadsService";
import ClonRAVatar from "../shared/src/models/clonR/clonRAvatar";

const functions = firebase.app().functions('europe-west3');

const db = database.firestore();

const ClonRAvatarsService = {


    async getAvatars(clientId: string): Promise<ClonRAvatar[] | null> {

        const avatars: ClonRAvatar[] = [];

        try {

            if (clientId) {
                const querySnapshot = await db.collection("clients").doc(clientId)
                    .collection("clonRAvatars")
                    .where("isDeleted", "==", false)
                    .orderBy("createdAt", "desc")
                    .get();


                querySnapshot.forEach((doc) => {

                    const avatar = new ClonRAvatar();
                    avatar.fromObject(doc.id, doc.data());

                    avatars.push(avatar);
                });

            }

            return avatars;

        } catch (error) {
            console.log("Error getting avatars: ", error);
            return null;
        }

    },

    async getAvatar(clientId: string, avatarId: string): Promise<ClonRAvatar | null> {

        if (!avatarId) return null;


        const doc = await db.collection("clients").doc(clientId)
            .collection("clonRAvatars").doc(avatarId)
            .get();

        try {

            if (doc.exists) {

                const avatar = new ClonRAvatar();
                avatar.fromObject(avatarId, doc.data());

                return avatar;

            } else {
                console.log("getAvatar: No such document: " + avatarId);
                return null;
            }

        } catch (error) {
            console.log("Error getting avatar: ", error);
            return null;
        };

    },



    async updateAvatar(avatar: ClonRAvatar, clientId: string): Promise<string | null> {

        try {

            if (avatar.id) {
                await db.collection("clients").doc(clientId)
                    .collection("clonRAvatars").doc(avatar.id)
                    .set(avatar.toJSON(), { merge: true });

                return avatar.id;

            } else {
                // create a new ClonRAvatar
                const docRef = await db.collection("clients").doc(clientId)
                    .collection("clonRAvatars")
                    .add(avatar.toJSON());

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating avatar: ", error);
        };

        return null;
    },

    // mark as deleted
    async deleteAvatar(avatar: ClonRAVatar, deletedByUserId: string, clientId: string): Promise<void> {

        try {
            await db.collection("clients").doc(clientId)
                    .collection("clonRAvatars").doc(avatar.id)
                    .set({
                        isDeleted: true,
                        deletedAt: new Date(),
                        deletedBy: deletedByUserId
                    }, { merge: true });

            // now delete video file for this avatar
            await FileUploadsService.deleteFile(`clients/${clientId}/clonRAvatars/${avatar.id}.${avatar.videoFileExtension}`);

        } catch (error) {
            console.log("Error deleting ClonRAvatar: ", error);
        };
    }

}

export default ClonRAvatarsService;