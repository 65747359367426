import React, { useEffect, useState } from 'react';
import FileUploadsService from '../services/fileUploadsService';
import { StorageError, TaskState } from 'firebase/storage';
import ProgressDialog from './dialogs/progressDialog';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface Props {
    targetFileName?: string, // in case we want to force a file name
    buttonLabel: string,
    targetPath: string,
    showProgressDialog: boolean,
    maxFileSizeMB?: number,
    mimeType?: string,
    generateVideoThumbnail: boolean,
    onUploadStateChanged?: (progress: number, state: TaskState) => void,
    onUploadFinished?: (downloadUrl: string, fileExtension: string, videoThumbnailUrl: string) => void,
    onUploadError?: (error: StorageError) => void
}


const FileUploader: React.FC<Props> = ({ targetFileName, buttonLabel, targetPath, showProgressDialog, maxFileSizeMB, mimeType, generateVideoThumbnail, onUploadStateChanged, onUploadFinished, onUploadError }) => {

    const [file, setFile] = useState<any>(null);
    const [progress, setProgress] = useState(0);
    const [uploadState, setUploadState] = useState<TaskState>("paused");
    const [isFileTooBigErrorVisible, setIsFileTooBigErrorVisible] = useState(false);
    const [isWrongFileTypeErrorVisible, setIsWrongFileTypeErrorVisible] = useState(false);

    useEffect(() => {
        setProgress(0);
        setFile(null);
        setIsFileTooBigErrorVisible(false);
        setIsWrongFileTypeErrorVisible(false);
    }, []);

    const onFileChange = (e) => {

        setFile(null);

        if (e.target.files.length > 0) {
            const newFile = e.target.files[0];

            const fSizeMB = newFile.size / 1024 / 1024;
            if (maxFileSizeMB !== undefined && fSizeMB >= maxFileSizeMB) {
                setIsFileTooBigErrorVisible(true);
                return;
            }

            // check if type is video/
            if (mimeType !== undefined && newFile.type.toLowerCase().indexOf(mimeType.toLowerCase()) === -1) {
                setIsWrongFileTypeErrorVisible(true);
                return;
            }

            setFile(newFile);
            setIsFileTooBigErrorVisible(false);
            setIsWrongFileTypeErrorVisible(false);

            // start upload
            setProgress(0);
            const uploadTask = FileUploadsService.uploadFileWithProgress(newFile, targetPath, (targetFileName ?? null), generateVideoThumbnail, handleUploadStateChanged, handleUploadFinished, handleUploadError)
        }

    }

    const handleUploadStateChanged = (progress: number, state: TaskState) => {
        console.log(`upload progress: ${progress}%, state: ${state}`);

        setProgress(progress);
        setUploadState(state);

        if (onUploadStateChanged) onUploadStateChanged(progress, state);
    }

    const handleUploadFinished = (downloadUrl: string, fileExtension: string, videoThumbnailUrl: string) => {
        console.log(`upload finished: ${downloadUrl}`);
        setUploadState("success");

        if (onUploadFinished) onUploadFinished(downloadUrl, fileExtension, videoThumbnailUrl);
    }

    const handleUploadError = (error: StorageError) => {
        console.log(`upload error: ${error}`);
        setUploadState("error");

        if (onUploadError) onUploadError(error);
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <div>

            <ProgressDialog
                visible={uploadState === "running" && showProgressDialog}
                progress={progress}
                title='Ihre Datei wird hochgeladen'
                label='Bitte haben Sie einen Moment Geduld...'
            />

            <Button component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                {buttonLabel}
                <VisuallyHiddenInput type="file" onChange={onFileChange} accept={mimeType ? mimeType + '/*' : "*"}/>
            </Button>

            {isFileTooBigErrorVisible && <div style={{ color: "red" }}>Die Datei ist zu groß, Sie können maximal {maxFileSizeMB} MB hochladen.</div>}
            {isWrongFileTypeErrorVisible && <div style={{ color: "red" }}>Falscher Dateityp, Sie können nur Dateien vom Typ {mimeType} hochladen.</div>}
        </div>
    );
}

export default FileUploader;
