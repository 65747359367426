
import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box/Box';

interface Props {
    visible: boolean;
    title: string;
    label: string;
    progress?: number;
}

const ProgressDialog: React.FC<Props> = ({ visible, title, label, progress }) => {

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={visible}
        //maxWidth="lg"
        //fullWidth={true}
        //disableEnforceFocus={true}
        >
            <DialogTitle id="customized-dialog-title">
                {title}
            </DialogTitle>

            <DialogContent
                dividers
            //style={{minHeight:'600px'}}
            >

                <Grid container>
                    <Grid item xs={12}>
                        <span>{label}</span>
                    </Grid>
                    <Grid item xs={12} textAlign={'center'}>
                        {progress !== undefined ?
                            <>
                                <LinearProgress variant='determinate' value={progress} />
                                <Box mt={1} >{`${Math.round(progress)}%`}</Box>
                            </>
                            :
                            <LinearProgress />
                        }
                    </Grid>
                </Grid>

            </DialogContent>

        </Dialog>

    );
}

export default ProgressDialog;
