
import React, {useContext} from "react";

import Button from '@mui/material/Button';
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import VideoFormItem from "../../../src/shared/src/models/formEditor/videoFormItem";
import { FormItemCtrlProps } from "./formItemCtrl";
import { Align } from "../../../src/shared/src/models/formEditor/formItem";
import Utils from "../../shared/src/utils/utils";
import { GlobalContext } from "../../GlobalContext";


interface Props extends FormItemCtrlProps {
    item: VideoFormItem
}

const VideoFormItemCtrl: React.FC<Props> = (
    {
        patientId,
        documentId,
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode,
        formUser
    }) => {



    const {currentUser} = useContext(GlobalContext);


    function renderHtml(): JSX.Element {

        const textAlignStrings: any = ["left", "center", "right"];
        const textAlignString = textAlignStrings[item.videoAlign];


        if (item.videoSource) {
            return (
                <div style={{ textAlign: textAlignString }}>
                    <iframe
                        title="psign-video"
                        style={{ width: item.videoWidth + "px", height: item.videoWidth + "px" }}
                        className=""
                        allowFullScreen={true}
                        frameBorder="0"
                        src={`https://www.youtube.com/embed/${Utils.getYouTubeId(item.videoSource)}?autoplay=1&rel=0`}>
                    </iframe>
                </div>
            );

        } else {
            return (
                <div style={{ textAlign: textAlignString }}>
                    <img
                        style={{ width: item.videoWidth + "px" }}
                        src="../../images/video-placeholder.svg"
                        alt=""
                    />
                </div>
            );
        }


    }

    function renderProperties(): JSX.Element {
        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <strong>Video</strong>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="YouTube Video ID"
                            value={item.videoSource}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "videoSource")}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            inputProps={{ min: "1", max: "600", step: "1" }}
                            label="Breite"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                            }}
                            value={item.videoWidth}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "videoWidth")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="outlined" size="small">
                            <Button
                                startIcon={<FormatAlignLeftIcon />}
                                variant={item.videoAlign === Align.left ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.left, "videoAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignCenterIcon />}
                                variant={item.videoAlign === Align.center ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.center, "videoAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignRightIcon />}
                                variant={item.videoAlign === Align.right ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.right, "videoAlign")}
                            />
                        </ButtonGroup>
                    </Grid>

                </Grid>

            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {
        const textAlignStrings: any = ["left", "center", "right"];
        const textAlignString = textAlignStrings[item.videoAlign];

        if (item.videoSource) {
            return (
                <div className="kt-videoFormItemCtrl">
                    <div style={{ textAlign: textAlignString }}>
                        <iframe
                            title="psign-video"
                            style={{ width: item.videoWidth + "px", height: item.videoWidth + "px" }}
                            className=""
                            allowFullScreen={true}
                            frameBorder="0"
                            src={`https://www.youtube.com/embed/${Utils.getYouTubeId(item.videoSource)}?autoplay=1&rel=0`}>
                        </iframe>
                    </div>
                </div>
            );

        } else {
            return (
                <div  className="kt-videoFormItemCtrl" style={{ textAlign: textAlignString }}>
                    <img
                        style={{ width: item.videoWidth + "px" }}
                        src="../../images/video-placeholder.svg"
                        alt=""
                    />
                </div>
            );
        }
    }


    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }


}


export default VideoFormItemCtrl;