
import DateUtils from "../../utils/dateUtils";
import { FormItemEnum, FormItemType, InputFormItemInterface } from "./formItem";
import FormRow from "./formRow";
import InputTextFormItem from "./inputTextFormItem";
import SignatureFormItem from "./signatureFormItem";


// the name Document is reserved by the DOM thats why we added the P to the name
class PDocument {
    id: string = "";
    templateId: string = ""; // if created of a template

    patientId: string = "";
    appointmentId: string = "";
    doctorId: string = "";

    name: string = "";
    languages: { key: string, name: string }[] = [{
        key: "de",
        name: ""
    }];

    // used for document templates
    expiresAfter: string = "6-m"; // 0, 1-d, 1-w, 1-m, 1-y

    // used for real created documents from templates
    // gets calculated and then saved from creation date + expireasAfter
    expiresAt: Date | null = null;

    mandatory: boolean = false;

    createdAt: Date | null = new Date();

    pdfCreatedAt: Date | null = null;

    fileSrc: string = ""; // path to generated PDF file

    formRows: FormRow[] = [];

    status: "none" | "expired" | "signed" | "sent" = "none";

    sortIndex: number = 0; // this defines the sort order in which the patient will fill the forms

    cardinality: number = 0;
    __reorder__: string = "";

    clone(): PDocument {
        const d = new PDocument();

        d.id = this.id;
        d.templateId = this.templateId;

        d.patientId = this.patientId;
        d.appointmentId = this.appointmentId;
        d.doctorId = this.doctorId;

        d.name = this.name;
        d.languages = this.languages;

        d.expiresAfter = this.expiresAfter;
        d.expiresAt = this.expiresAt ? new Date(this.expiresAt) : null;

        d.mandatory = this.mandatory;

        d.createdAt = this.createdAt ? new Date(this.createdAt) : null;

        d.pdfCreatedAt = this.pdfCreatedAt ? new Date(this.pdfCreatedAt) : null;

        d.fileSrc = this.fileSrc;

        d.status = this.status ?? "none";

        d.sortIndex = this.sortIndex;

        d.cardinality = this.cardinality;
        d.__reorder__ = this.__reorder__;

        d.formRows = [];

        for (let i = 0; i < this.formRows.length; i++) {
            d.formRows.push(this.formRows[i].clone());
        }

        return d;
    }

    // if we store directly to firestore use date
    // if we send json to cloud functions we need to use getTime()
    getTimeOrDate(value: Date | null, useDate: boolean) {
        if (useDate) {
            return value ?? null;
        } else {
            return value ? value.getTime() : null;
        }
    }

    toJSON(includeUserInputs: boolean, includeFormRows: boolean = true, useToDate: boolean = true): object {
        const obj = {
            id: this.id,
            templateId: this.templateId,

            patId: this.patientId,
            aptId: this.appointmentId,
            docId: this.doctorId,

            name: this.name,
            lang: this.languages,

            expiresAfter: this.expiresAfter,
            expiresAt: this.getTimeOrDate(this.expiresAt, useToDate),

            mandatory: this.mandatory,

            createdAt: this.getTimeOrDate(this.createdAt, useToDate),
            pdfCreatedAt: this.getTimeOrDate(this.pdfCreatedAt, useToDate),

            status: this.status,

            fileSrc: this.fileSrc,

            sortIndex: this.sortIndex,

            cardinality: this.cardinality,

            formRows: [] as any[]
        };

        if (includeFormRows) {
            for (let i = 0; i < this.formRows.length; i++) {
                obj.formRows.push(this.formRows[i].toJSON(includeUserInputs));
            }
        }

        return obj;
    }

    fromObject(id: string | null, o: any) {

        this.id = id !== null ? id : o.id;
        this.templateId = o.templateId ?? "";

        this.patientId = o.patId ?? "";
        this.appointmentId = o.aptId ?? "";
        this.doctorId = o.docId ?? "";

        this.name = o.name ?? "";
        this.languages = o.lang ? ([...o.lang] ?? [{ key: "de", name: "" }]) : [{ key: "de", name: "" }];

        this.expiresAfter = o.expiresAfter ?? "0-m";
        this.expiresAt = o.expiresAt ? DateUtils.getDate(o.expiresAt) : null;

        this.mandatory = o.mandatory === true;

        this.createdAt = o.createdAt ? DateUtils.getDate(o.createdAt) : null;
        this.pdfCreatedAt = o.pdfCreatedAt ? DateUtils.getDate(o.pdfCreatedAt) : null;

        this.status = o.status ?? "none";

        this.fileSrc = o.fileSrc ?? "";

        this.sortIndex = o.sortIndex ?? 0;

        this.cardinality = o.cardinality ?? 0;
        this.__reorder__ = o.name;

        this.formRows = [];
        if (o.formRows) {
            for (let i = 0; i < o.formRows.length; i++) {
                const formRowObj = o.formRows[i];
                let newFormRow = new FormRow();

                if (formRowObj !== null) {
                    newFormRow.fromObject(formRowObj);
                    this.formRows.push(newFormRow);
                }
            }
        }

    }

    getStatusString(): string {
        return "fehlt";
    }


    getLanguageProperty(language: string, propertyName: string): string | null {
        let lang = this.languages.find(element => element.key === language);

        // property not found for that language
        // so try the first language we find
        if (!lang && this.languages.length > 0) {
            lang = this.languages[0];
        }

        return lang ? (lang as any)[propertyName] : null;

    }

    setLanguageProperty(language: string, propertyName: string, newValue: string) {
        const lang = this.languages.find(element => element.key === language);
        if (lang) {
            (lang as any)[propertyName] = newValue;
        } else {
            const newLanguage: any = {
                key: language,
                name: ""
            };


            (newLanguage as any).propertyName = newValue;
            this.languages.push(newLanguage);
        }
    }

    removeLanguage(language: string) {
        const index = this.languages.findIndex((o) => {
            return o.key === language;
        })
        if (index !== -1) this.languages.splice(index, 1);
    }

    mergeDocumentFormItemValues(document: PDocument) {

        if (document && document.formRows) {


            for (let r = 0; r < document.formRows.length; r++) {
                const row = document.formRows[r];

                for (let c = 0; c < row.columns.length; c++) {
                    const formItem = row.columns[c];

                    const originalItem = this.getFormItemById(formItem.id);

                    let value: any = null;

                    switch (formItem.type) {
                        case FormItemEnum.inputText:
                        case FormItemEnum.radio:
                        case FormItemEnum.dropdown:
                            value = (formItem as InputTextFormItem).value;
                            (originalItem as InputTextFormItem).value = value;
                            break;

                        case FormItemEnum.signature:
                            value = (formItem as SignatureFormItem).signature;
                            (originalItem as SignatureFormItem).signature = value;
                            break;

                        default:
                            break;
                    }

                }
            }
        }
    }

    resetDoctorInputs() {

        for (let r = 0; r < this.formRows.length; r++) {
            const row = this.formRows[r];

            for (let c = 0; c < row.columns.length; c++) {
                const formItem = row.columns[c];

                if (formItem.isInputType() && (formItem as InputFormItemInterface).inputUser === "doctor") {

                    switch (formItem.type) {
                        case FormItemEnum.inputText:
                        case FormItemEnum.radio:
                        case FormItemEnum.dropdown:
                            (formItem as InputTextFormItem).value = "";
                            break;

                        case FormItemEnum.signature:
                            (formItem as SignatureFormItem).signature = "";
                            break;

                        default:
                            break;
                    }
                }

            }
        }

    }

    getFormItemById(id: string): FormItemType | null {
        for (let r = 0; r < this.formRows.length; r++) {
            const row = this.formRows[r];

            for (let c = 0; c < row.columns.length; c++) {
                const colum = row.columns[c];

                if (colum.id === id) {
                    return colum
                }
            }
        }

        return null;
    }

    static cloneList(documents: PDocument[]): PDocument[] {
        const clonedDocuments: PDocument[] = [];

        documents.forEach(doc => {
            const clonedDoc = doc.clone();
            clonedDocuments.push(clonedDoc);
        });

        return clonedDocuments;
    }
};

export default PDocument;