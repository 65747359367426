import { useState, useEffect, useContext, Fragment } from 'react';
import { Link } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Avatar from '@mui/material/Avatar';

import CalendarsService from '../../../services/calendarsService';
import Calendar, { CalendarLicense } from '../../../models/calendar';
import DeleteIcon from "@mui/icons-material/Delete";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import { updateRowPosition } from '../../../utils';
import { GlobalContext } from '../../../GlobalContext';


function CalendarsPage() {

    const [calendars, setCalendars] = useState<Calendar[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const { currentClient } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);

    useEffect(() => {

        updateCalendars();

        // eslint-disable-next-line
    }, [currentClient]);

    async function updateCalendars() {

        if (!currentUser) {
            return;
        }

        setIsLoading(true);

        try {
            const result = await CalendarsService.getCalendars(true, currentClient.id, currentUser.locationId);

            setIsLoading(false);
            if (result) {
                setCalendars(result);
            }

        } catch (error) {
            setIsLoading(false);
        }
    }

    function getLicenseDisplayName(license: CalendarLicense): string {
        switch (license) {
            case "CalendR":
                return "CalendR";

            case "CalendR_Plus":
                return "CalendR Plus";

            case "CalendR_AI":
                return "CalendR AI";

            case "CalendR_AI_Plus":
                return "CalendR AI Plus";

            default:
                return "CalendR";
        }
    }

    const columns: any[] = [
        {
            field: 'avatarUrl', headerName: 'Benutzer', width: 100, renderCell: (params) => (
                <Link to={`/settings/calendar/${params.row.id}`}>
                    <Avatar src={params.row.avatarUrl} alt={params.row.name} />
                </Link>
            )
        },
        {
            field: 'name', headerName: 'Kalendername', width: 300, renderCell: (params) => (
                <Link to={`/settings/calendar/${params.row.id}`}>{params.row.name}</Link>
            )
        },
        {
            field: 'abbreviation', headerName: 'Kürzel', width: 150, renderCell: (params) => (
                <Link to={`/settings/calendar/${params.row.id}`}>{params.row.abbreviation}</Link>
            )
        },
        { field: 'locationName', headerName: 'Standort', width: 300 },
        {
            field: 'allowOnlineAppointments', headerName: 'Online Termine', width: 150, renderCell: (params) => (
                params.row.allowOnlineAppointments ? <CheckRoundedIcon /> : <Fragment />
            )
        },
        {
            field: 'internal', headerName: 'Intern', width: 100, renderCell: (params) => (
                params.row.internal ? <CheckRoundedIcon /> : <Fragment />
            )
        },
        {
            field: 'license', headerName: 'Lizenz', width: 150, renderCell: (params) => (
                getLicenseDisplayName(params.row.license)
            )
        },
        {
            field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
                <IconButton onClick={(e) => onDeleteRowClick(params.row)}>
                    <DeleteIcon />
                </IconButton>)
        }
    ];


    async function onDeleteRowClick(row) {

        if (calendars.length === 1) {
            alert("Sie können nicht den einzigen Kalender löschen.");
            return;
        }

        const reallyDelete = window.confirm(`Wollen Sie den Kalender ${row.name} wirklich löschen?`);
        if (reallyDelete) {
            await CalendarsService.deleteCalendar(currentClient.id, row.locationId, row.id);
            updateCalendars();
        }

    }

    const handleRowOrderChange = async (params) => {
        setIsLoading(true);
        const newRows = updateRowPosition<Calendar>(
            params.oldIndex,
            params.targetIndex,
            calendars,
        );

        for (let i = 0; i < newRows.length; i++) {
            const e = newRows[i];
            e.cardinality = i + 1;

            CalendarsService.updateCalendar(currentClient.id, currentUser!.locationId, e);
        }
        setCalendars(newRows);
        setIsLoading(false);
    };

    return (
        <Box className="kt-page">
            <h2>Terminkalender</h2>

            <Grid container>
                <Grid item>
                    <ButtonGroup>
                        <Button component={Link} to="/settings/calendar/create" startIcon={<AddCircleOutlineIcon />}>
                            Terminkalender anlegen
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Box className="kt-data-grid-container">
                <DataGridPro
                    loading={isLoading}
                    rows={calendars}
                    columns={columns}
                    rowReordering
                    onRowOrderChange={handleRowOrderChange}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

        </Box>
    );
}

export default CalendarsPage;